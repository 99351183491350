import { buildingDetailsBreadcrumb } from "../buildings/buildings-breadcrumbs";
import { clientDetailsBreadcrumb } from "../clients/client-breadcrumb";

export interface IParams {
  client: { name?: string; id: number };
  building: { name?: string; id: number };
  plant?: { name?: string; id: number };
}

export const plantDetailsBreadcrumb = (props: IParams) => {
  const { client, building, plant } = props;

  const buildingDetails = buildingDetailsBreadcrumb({
    client: { name: client?.name, id: client?.id },
    building: { name: building?.name, id: building?.id },
  });

  const plantDetails = [
    ...buildingDetails,
    {
      title: "Anlage",
      path: `/clients/${client.id}/buildings/${building?.id}`,
    },
    {
      title: plant?.name ? plant?.name : "Loading...",
      path: `/clients/${client.id}/buildings/${building?.id}/plants/${plant?.id}`,
      disabledPath: true,
    },
  ];

  return plantDetails.filter((item) => item.title !== "");
};

export const createPlantsBreadcrumb = (props: IParams) => {
  const { client, building } = props;

  const clientDetails = clientDetailsBreadcrumb({
    client: { name: client?.name, id: client?.id },
  });

  const buildingDetails = [
    ...clientDetails,
    { title: "Gebäude", path: `/clients/${client.id}` },
    {
      title: building?.name ? building?.name : "Loading...",
      path: `/clients/${client.id}/buildings/${building?.id}`,
    },
    {
      title: "Anlage",
      path: `/clients/${client.id}/buildings/${building?.id}`,
      disabledPath: true,
    },
  ];

  return buildingDetails.filter((item) => item.title !== "");
};
