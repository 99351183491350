import React, { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";

interface ComponentOutletProps {}

const ComponentOutlet: FunctionComponent<ComponentOutletProps> = () => {
  return <Outlet />;
};

export default ComponentOutlet;
