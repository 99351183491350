import {create} from "zustand";

export interface BreadcrumbItem {
  path: string;
  title: string;
  disabledPath?: boolean;
}

interface BaseInfo {
  name?: string;
  id: number;
}

export interface Breadcrumb {
  items: BreadcrumbItem[];
  client?: BaseInfo;
  building?: BaseInfo;
  plant?: BaseInfo;
  product?: BaseInfo;
}

interface BreadcrumbStoreState {
  items: BreadcrumbItem[];
  client: BaseInfo;
  building: BaseInfo;
  plant: BaseInfo;
  product: BaseInfo;
}

type BreadcrumbStoreActions = {
  updateBreadcrumb: (newBreadcrumb: BreadcrumbItem[]) => void;
  removeLastBreadcrumb: () => void;
  resetBreadcrumb: () => void;
};

const useBreadcrumbStore = create<
  BreadcrumbStoreState & BreadcrumbStoreActions
>((set) => ({
  items: [],
  client: { id: 0, name: "" },
  building: { id: 0, name: "" },
  plant: { id: 0, name: "" },
  product: { id: 0, name: "" },
  updateBreadcrumb: (newBreadcrumb) => set({ items: newBreadcrumb }),
  removeLastBreadcrumb: () =>
    set((state) => ({ items: state.items.slice(0, -1) })),
  resetBreadcrumb: () => set({ items: [] }),
}));

export default useBreadcrumbStore;
