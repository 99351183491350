import Box from "@mui/joy/Box";
import extendTheme from "../../assets/themes/custom-theme";
import Header from "../layout/header";
import React, { useEffect } from "react";
import Sidebar from "../layout/side-bar";
import { CssVarsProvider } from "@mui/joy/styles";
import { Outlet, useLocation } from "react-router-dom";
import { RequireAuth } from "react-auth-kit";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BreadcrumbItems from "./breadcrumbs";
import { Alert, useMediaQuery } from "@mui/material";
import useOnlineStatusStore from "../../stores/store-online";

const Layout = () => {
  const isMobile = useMediaQuery("(min-width: 600px)");
  const location = useLocation();
  const { isOnline, setIsOnline } = useOnlineStatusStore();

  useEffect(() => {
    // Add event listeners to update online/offline status
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    // Clean up event listeners on unmount
    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, [setIsOnline]);

  return (
    <>
      <CssVarsProvider
        defaultMode="dark"
        disableTransitionOnChange
        theme={extendTheme}
      >
        <Header />
        <Sidebar />
      </CssVarsProvider>
      <Box
        component="main"
        margin={location.pathname !== "/messenger" ? "20px" : "0px"}
        marginBottom={0}
        marginTop={isMobile ? "0px" : "60px"}
        sx={{
          overflowY: "auto",
          height: "100%",
          width: "100%",
          flex: 1,
        }}
      >
        {!isOnline ? (
          <Box position="fixed" mt="20px" right="20px">
            <Alert variant="filled" severity="info">
              You're offline
            </Alert>
          </Box>
        ) : undefined}
        <ToastContainer />
        <BreadcrumbItems />
        <RequireAuth loginPath={"/login"}>
          <Outlet />
        </RequireAuth>
      </Box>
    </>
  );
};

export default Layout;
