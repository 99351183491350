import React, { useCallback, useEffect } from "react";
import Box from "@mui/joy/Box";
import MainRoutes from "../../routes/main-routers";
import UserPermissions from "../layout/user-permissions";
import { AuthProvider } from "react-auth-kit";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { QueryClient } from "@tanstack/react-query";
import { useMode } from "../layout/theme";
import { fetchToken, onMessageListener } from "../../configs/firebase";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import * as Sentry from "@sentry/react";
import { showError404Alert } from "../shared/alerts";
import { ErrorsEnum } from "../../models/errors.enum";

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 2000,
      retry: false,
      refetchOnWindowFocus: false,
      onError: async (err: any) => {
        const { status } = err?.response;
        console.log(status, "status");
        if (status === ErrorsEnum.Not_FOUND) {
          await showError404Alert();
          window.history.back();
          return;
        }
      },
    },
  },
});

const App = () => {
  const [theme] = useMode();

  const initializeMessaging = useCallback(async () => {
    await fetchToken();

    try {
      onMessageListener();
    } catch (err) {
      console.log("failed: ", err);
    }
  }, []); // Empty dependency array to ensure the function is memoized

  useEffect(() => {
    initializeMessaging();
  }, []); // Call the function with dependency on itself to ensure it's only called once

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
      onSuccess={() => {
        // resume mutations after initial restore from localStorage was successful
        queryClient.resumePausedMutations();
      }}
    >
      <Sentry.ErrorBoundary>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ display: "flex", minHeight: "100dvh" }}>
              <AuthProvider
                authType={"cookie"}
                authName={"_auth"}
                cookieDomain={window.location.hostname}
                cookieSecure={false}
              >
                <UserPermissions>
                  <MainRoutes />
                </UserPermissions>
              </AuthProvider>
            </Box>
          </ThemeProvider>
        </LocalizationProvider>
      </Sentry.ErrorBoundary>

      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </PersistQueryClientProvider>
  );
};

export default App;
