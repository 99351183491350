import { GridColDef } from "@mui/x-data-grid";

export const columns: GridColDef[] = [
  {
    field: "task_name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
  },
  {
    field: "created_by",
    headerName: "Zuweisen",
    flex: 1,
  },
  {
    field: "start_date",
    headerName: "Startdatum",
    flex: 1,
  },
  {
    field: "due_date",
    headerName: "Fälligkeitsdatum",
    flex: 1,
  },
];
