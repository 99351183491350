import { Box, List, ListItem, ListItemText, Paper, Typography, useTheme } from '@mui/material';
import React from 'react';
import { tokens } from '../layout/theme';

interface FileCategory {
  germanTitle: string;
  englishDescription: string;
  fileUrl: string;
}

const fileCategories: FileCategory[] = [
  { germanTitle: 'Anlagenaufnahme', englishDescription: 'plant_recording', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'Bedienungsanleitungen', englishDescription: 'operating_instructions', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'Checklisten', englishDescription: 'checklists', fileUrl: '/downloads/checklists.pdf' },
  { germanTitle: 'Inbetriebnahmeprotokolle', englishDescription: 'commissioning_report', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'Leistungsnachweise', englishDescription: 'proof_of_performance', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'Vorschriften', englishDescription: 'rules', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'Wartungsprotokolle', englishDescription: 'maintenance_reports', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'Sonstiges', englishDescription: 'miscellaneous', fileUrl: '/downloads/dummy.pdf' },
];

const DownloadArea1: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Paper elevation={5}>
    <Box
       display="flex"
       flexDirection="column"
       bgcolor={colors.primary[400]}
       borderRadius="5px"
       marginTop="20px"
     >
    <Box
      ml="20px"
      mt="65px"
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "93vh",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Dateien zum Herunterladen
      </Typography>
      <List>
        {fileCategories.map((category, index) => (
          <ListItem sx={{ listStyle: 'outside' , color: 'white !important', textDecoration: 'underline'}} key={index} component="a" href={category.fileUrl} download>
            <ListItemText
              primary={category.germanTitle}
            />
          </ListItem>
        ))}
      </List>
    </Box>
    </Box>
    </Paper>
  );
};

export default DownloadArea1;
