import Can from "../../casl/Can";
import DataTable from "../shared/table";
import Header from "../shared/header";
import React, { FunctionComponent, useState } from "react";
import { Box } from "@mui/material";
import { columns } from "./assigned-tasks.columns";
import { Outlet } from "react-router-dom";
import { transformFilterItemToFilterObject } from "../../utils/helper";
import { useFetchAssignedTasks } from "../../api/tasks";
import {
  GridFilterItem,
  GridPaginationModel,
  GridSortItem,
} from "@mui/x-data-grid";

interface AssignedTasksListProps {}

const TasksList: FunctionComponent<AssignedTasksListProps> = (
  props: AssignedTasksListProps
) => {
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  // Filter
  const [filters, setFilters] = useState({});
  // Sort
  const [sort, setSort] = useState({});
  const {
    data: assignedTasksData,
    isLoading,
    isError,
  } = useFetchAssignedTasks(currentPage, itemsPerPage, filters, sort);
  const tasks = assignedTasksData?.data;
  const totalItems = assignedTasksData?.pager?.total;

  const parentPath = `activity/sub-tasks`;

  const handlePageChange = (newPage: GridPaginationModel) => {
    // Fetch new data from the server based on the new page
    setCurrentPage(newPage.page + 1);
    setItemsPerPage(newPage.pageSize);
  };

  const handleFilterModelChange = (filterItem: GridFilterItem) => {
    const filterObject = transformFilterItemToFilterObject(filterItem);
    setFilters(filterObject);
  };

  const handleSortModelChange = (sortItem: GridSortItem) => {
    setSort(sortItem);
  };

  return (
    <>
      {!isError && (
        <Box
          sx={{
            marginTop: "60px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header title="Aufgaben" />
          </Box>
          <Can I="view" a="Tasks">
            <Box marginTop="40px">
              <DataTable
                parentPath={parentPath}
                data={tasks ?? []}
                fieldsNames={columns}
                boxHeight={75}
                noDataMessage="Keine zugewiesene Aufgabe für Sie"
                currentPage={currentPage - 1}
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
                ssrFilter={true}
                isLoading={isLoading}
                onFilterChange={handleFilterModelChange}
                onSortChange={handleSortModelChange}
                ssrSort={true}
              />
            </Box>
          </Can>
          <Outlet />
        </Box>
      )}
    </>
  );
};

export default TasksList;
