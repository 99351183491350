import React from "react";
import { FunctionComponent } from "react";
import { Link, NavigateFunction } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "./error.css";
interface NotFoundProps {
  backTo?: boolean;
}

const NotFound: FunctionComponent<NotFoundProps> = (props) => {
  const navigate: NavigateFunction = useNavigate();

  const { backTo } = props;
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        width="100%"
        height="100vh"
      >
        <Typography
          variant="h1"
          className="error-head"
          fontSize="160px"
          fontWeight="700"
        >
          404
        </Typography>
        <Typography
          variant="h2"
          paddingBottom={1}
          fontWeight="700"
          lineHeight="1.1"
          marginBottom="0.5em"
        >
          Oops! Why you’re here?
        </Typography>
        <Typography
          variant="h6"
          lineHeight="1.1"
          marginBottom="1em"
          letterSpacing="1px"
        >
          It looks like you’re try to access a page that either has been deleted
          or never existed.
        </Typography>
        {!backTo ? (
          <Button
            variant="contained"
            component={Link}
            to="/dashboard"
            className="error-btn"
            sx={{
              margin: "1em 0em",
            }}
          >
            Go to Home
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              navigate(-1);
            }}
            className="error-btn"
            sx={{
              margin: "1em 0em",
            }}
          >
            Back
          </Button>
        )}
      </Box>
    </>
  );
};

export default NotFound;
