import React from "react";
import Header from "../shared/header";
import { Box } from "@mui/material";

const ParametersPage = () => {
  return (
    <Box ml="20px" mt="65px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="PARAMETERS" subtitle="Willkommen bei Ihren Parametern" />
      </Box>
    </Box>
  );
};

export default ParametersPage;
