import * as React from "react";
import Avatar from "@mui/joy/Avatar";
import AvatarGroup from "@mui/joy/AvatarGroup";
import { IdentityProps } from "../../utils/chat-types";

type GroupedAvatarsProps = {
  members: IdentityProps[];
};

export default function GroupedAvatars({ members }: GroupedAvatarsProps) {
  const avatars = Array.from({ length: members.length }, (_, index) => (
    <Avatar alt={members[index].username} key={index} />
  ));

  return (
    <AvatarGroup>
      {avatars.slice(0, 2)}
      {members.length > 2 && <Avatar>+{members.length - 2}</Avatar>}
    </AvatarGroup>
  );
}
