import React, { FunctionComponent, ReactNode } from "react";
import { Button, ButtonPropsColorOverrides } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { Link as RouterLink } from "react-router-dom";

interface LinkButtonProps {
  startIcon: ReactNode;
  label: string;
  disabled?: boolean;
  to: string;
  type?: "button" | "submit" | "reset";
  color?: OverridableStringUnion<
    | "secondary"
    | "inherit"
    | "primary"
    | "success"
    | "error"
    | "info"
    | "warning",
    ButtonPropsColorOverrides
  >;
}

const LinkButton: FunctionComponent<LinkButtonProps> = (props) => {
  const { startIcon, label, color, type, to, disabled = false } = props;
  return (
    <>
      <Button
        startIcon={startIcon}
        color={color ? color : "secondary"}
        variant="contained"
        size="small"
        type={type}
        sx={{
          fontSize: "1rem",
          textTransform: "none",
        }}
        component={RouterLink}
        to={to}
        disabled={disabled}
      >
        {label}
      </Button>
    </>
  );
};

export default LinkButton;
