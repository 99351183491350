import { useQuery } from "@tanstack/react-query";
import { ApiService } from "./api.service";
import { IResponse } from "../models/response";
import { IdentityProps } from "../utils/chat-types";
import { getCookieValue } from "../utils/helper";

// Get user contacts
export const useFetchUserContacts = () => {
  const fetchUserContacts = async (): Promise<IResponse<IdentityProps[]>> => {
    try {
      const authStatusCookie = getCookieValue("_auth_state");
      if (!authStatusCookie) {
        throw new Error("Auth status cookie not found.");
      }

      const authState = JSON.parse(decodeURIComponent(authStatusCookie));
      if (!authState) {
        throw new Error("Auth state not found.");
      }

      const response = await ApiService.get(
        `/get-user-contacts/${authState.id}`
      );

      const userContacts: IdentityProps[] = response.data.map(
        (contact: any) => ({
          id: contact.id.toString(),
          name: `${contact.first_name} ${contact.last_name}`,
          username: `${contact.first_name} ${contact.last_name}`,
          avatar: "",
          online: contact.online, // Use the correct property indicating online status
        })
      );

      return { status: true, data: userContacts };
    } catch (error) {
      throw error;
    }
  };

  return useQuery<IResponse<IdentityProps[]>, Error>(
    ["userContacts"],
    fetchUserContacts
  );
};
