import { IdentityProps } from "./chat-types";

export const openSidebar = () => {
  if (typeof document !== "undefined") {
    document.body.style.overflow = "hidden";
    document.documentElement.style.setProperty("--SideNavigation-slideIn", "1");
  }
};

export const closeSidebar = () => {
  if (typeof document !== "undefined") {
    document.documentElement.style.removeProperty("--SideNavigation-slideIn");
    document.body.style.removeProperty("overflow");
  }
};

export const toggleSidebar = () => {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--SideNavigation-slideIn");
    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
};

export const openMessagesPane = () => {
  if (typeof document !== "undefined") {
    document.body.style.overflow = "hidden";
    document.documentElement.style.setProperty("--MessagesPane-slideIn", "1");
  }
};

export const closeMessagesPane = () => {
  if (typeof document !== "undefined") {
    document.documentElement.style.removeProperty("--MessagesPane-slideIn");
    document.body.style.removeProperty("overflow");
  }
};

export const toggleMessagesPane = () => {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--MessagesPane-slideIn");
    if (slideIn) {
      closeMessagesPane();
    } else {
      openMessagesPane();
    }
  }
};

// Helper function to remove prefix from id
export function extractOriginalId(prefixedId: string) {
  const parts = prefixedId.split("_");
  if (parts.length === 2) {
    return parts[1];
  }
  // If the format is not as expected, return the original id
  return prefixedId;
}

export function mapDialogToChatProps(dialog: {
  last_message: { sent: number; file: { name: any }; text: any };
  unread_count: any;
  other_user_last_login: string | number | Date;
  type: "group" | "dialog";
  id: { toString: () => any };
  other_user_id: any;
  username: any;
  is_user2_online: any;
  group_name: any;
  participants: any[];
}) {
  let chatId;
  let lastMessage;
  const timestamp = new Date(dialog.last_message?.sent * 1000).toLocaleString();
  const unread = dialog.unread_count;
  const lastseen = new Date(dialog.other_user_last_login).toLocaleString();

  if (dialog.type === "dialog") {
    chatId = `dialog_${dialog.id.toString()}`;
    if (dialog.last_message && dialog.last_message.file) {
      lastMessage = dialog.last_message.file.name;
    } else {
      lastMessage = dialog.last_message?.text;
    }
    const sender = {
      id: dialog.other_user_id,
      name: dialog.username,
      username: dialog.username,
      avatar: "", // Set the appropriate avatar value
      online: dialog.is_user2_online, // Set the appropriate online value
    };

    return {
      id: dialog.id.toString(),
      sender: sender,
      unread: unread,
      lastseen: lastseen,
      lastMessage: lastMessage,
      timestamp: timestamp,
      type: "dialog",
    };
  } else {
    chatId = `group_${dialog.id.toString()}`;
    if (dialog.last_message && dialog.last_message.file) {
      lastMessage = dialog.last_message.file.name;
    } else {
      lastMessage = dialog.last_message?.text;
    }
    const sender = {
      id: dialog.id,
      name: dialog.group_name,
      username: dialog.group_name,
      avatar: "",
      online: false,
    };
    let members: IdentityProps[] = [];
    if (dialog.participants) {
      members = dialog.participants.map((member) => ({
        id: member.id,
        name: member.username,
        username: member.username,
        avatar: "",
        online: false,
      }));
    }
    return {
      id: chatId,
      sender: sender,
      unread: unread,
      lastseen: lastseen,
      lastMessage: lastMessage,
      timestamp: timestamp,
      type: "group",
      members: members,
    };
  }
}
