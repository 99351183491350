import {create} from "zustand";

interface OnlineStatusStore {
  isOnline: boolean;
  setIsOnline: (online: boolean) => void;
}

const useOnlineStatusStore = create<OnlineStatusStore>((set) => ({
  isOnline: navigator.onLine,

  setIsOnline: (online) => {
    set({ isOnline: online });
  },
}));

export default useOnlineStatusStore;
