import React from "react";
import { Box, Card, CardHeader, Switch, Divider } from "@mui/material";
import { updateNotificationSettings } from "../../api/notification";
import { useModeStore } from "../../stores/store-settings";

const NotificationsSettings = () => {
  const { notificationEnabled, enableNotification } = useModeStore();
  return (
    <Card>
      <CardHeader
        subheader="Verwalten Sie die Benachrichtigungen"
        title={
          <Box display="flex" alignItems="center">
            Benachrichtigungen
            <Switch
              color="success"
              checked={notificationEnabled}
              onChange={() => {
                enableNotification(); // Toggle the notificationEnabled in the useModeStore
                updateNotificationSettings(!notificationEnabled);
              }}
            />
          </Box>
        }
      />
      <Divider />
      {/* Add your content here */}
    </Card>
  );
};

export default NotificationsSettings;
