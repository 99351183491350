import React, { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";

interface ClientsOutletProps {}

const ClientsOutlet: FunctionComponent<ClientsOutletProps> = () => {
  return <Outlet />;
};

export default ClientsOutlet;
