import { GridColDef } from "@mui/x-data-grid";

export const columns: GridColDef[] = [
  {
    field: "title",
    headerName: "Titel",
    flex: 1,
  },
  {
    field: "body",
    headerName: "Text",
    flex: 1,
  },
  {
    field: "type",
    headerName: "Typ",
    flex: 1,
  },
  {
    field: "sent_at",
    headerName: "Gesendet an",
    flex: 1,
  },
];
