import { GridColDef } from "@mui/x-data-grid";

export const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "role",
    headerName: "Rolle",
    flex: 1,
    sortable: false,
  },
];
