import * as React from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Autocomplete from "@mui/joy/Autocomplete";
import { useFetchUserContacts } from "../../api/contacts";
import { ChatProps, IdentityProps } from "../../utils/chat-types";
import { getCookieValue } from "../../utils/helper";
import axios from "axios";
import useSocketStore from "../../stores/store-socket";
import { toggleMessagesPane } from "../layout/utils";
import { mapDialogToChatProps } from "../../utils/chat-helper";
import { BASE_CHAT_URL } from "../../api";

// Function to fetch dialogs based on the username
const fetchDialogsForUser = async (username: string): Promise<ChatProps[]> => {
  try {
    const authStatusCookie = getCookieValue("_auth_state");
    if (authStatusCookie) {
      const authState = JSON.parse(decodeURIComponent(authStatusCookie));
      if (authState) {
        const headers = {
          Authorization: `Bearer ${authState.token}`,
        };

        const selfEndpoint = `${BASE_CHAT_URL}/dialogs?otherUser=${username}`;

        const response = await axios.get(selfEndpoint, { headers });

        if (response.status === 200) {
          const dialogsData = response.data.data as any[]; // Assuming the response is an array

          // Convert the dialogsData into ChatProps[]
          const chats: ChatProps[] = dialogsData.map(
            (dialog) => mapDialogToChatProps(dialog) as ChatProps
          );

          return chats;
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }
    }

    // Return an empty array as the default value
    return [];
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export default function SearchInput() {
  const { dialogsListState, setSelectedChat } = useSocketStore();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<string[]>([]);
  const [userNames, setUserNames] = React.useState<string[]>([]);
  const [selectedUsername, setSelectedUsername] = React.useState<string | null>(
    null
  );
  const loading = open && options.length === 0;
  const usersData = useFetchUserContacts();
  const {
    setSocketConnectionState,
    socketConnectionState,
    socketRefCurrentState,
  } = useSocketStore();

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const fetchedUsersData = await usersData;
      const fetchedUserNames = fetchedUsersData.data?.data
        ? fetchedUsersData.data.data.map((user: IdentityProps) => user.name)
        : [];
      // Collect sender usernames from dialogsListState
      const senderUsernames = dialogsListState.map(
        (chat: ChatProps) => chat.sender.username
      );

      // Filter usersData usernames to exclude those already in senderUsernames
      const filteredUsernames = fetchedUserNames.filter(
        (username: any) => !senderUsernames.includes(username)
      );
      if (active) {
        setOptions([...senderUsernames, ...filteredUsernames]);
        setUserNames([...senderUsernames, ...filteredUsernames]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, usersData]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    if (selectedUsername) {
      const chatWithSelectedUser = dialogsListState.find(
        (chat: ChatProps) => chat.sender.username === selectedUsername
      );
      if (chatWithSelectedUser) {
        toggleMessagesPane();
        setSelectedChat(chatWithSelectedUser);
      } else {
        // Call the function directly
        fetchDialogsForUser(selectedUsername)
          .then((chats) => {
            setSocketConnectionState(
              socketConnectionState,
              socketRefCurrentState,
              chats
            );
            if (chats && chats.length > 0) {
              toggleMessagesPane();
              setSelectedChat(chats[0]);
            }
            setSelectedUsername(null); // Clear the selected username
          })
          .catch((error) => {
            // Handle error
          });
      }
    }
  }, [selectedUsername]);

  return (
    <FormControl>
      <FormLabel htmlFor="search-conversation">Mitglieder</FormLabel>
      {usersData.isLoading ? (
        <div>Loading...</div>
      ) : (
        <Autocomplete
          id="search-conversation"
          options={userNames}
          placeholder="suchen Gespräch..."
          disableCloseOnSelect
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionLabel={(option) => option}
          onChange={(event, value) => {
            setSelectedUsername(value);
          }}
          style={{ width: "100%" }}
        />
      )}
    </FormControl>
  );
}
