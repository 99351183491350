import axios from "axios";
import { BASE_CHAT_URL, token } from "./index";

const axiosChatInstance = axios.create({
  baseURL: BASE_CHAT_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export const ChatApiService = {
  get: async (url: string, params?: object) => {
    try {
      const response = await axiosChatInstance.get(url, { ...params });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  post: async (url: string, data?: object) => {
    try {
      const response = await axiosChatInstance.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postFormData: async (url: string, formData: FormData) => {
    try {
      const response = await axiosChatInstance.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
