import React, { FunctionComponent, ReactNode } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../layout/theme";
import { BoxProps } from "@mui/system";

interface BoxWrapProps extends BoxProps {
  children: ReactNode;
  marginTop?: string;
  hasBackground?: boolean;
}

const BoxWrap: FunctionComponent<BoxWrapProps> = ({
  children,
  marginTop,
  hasBackground = true,
  ...props
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <Box
        borderRadius="8px"
        bgcolor={hasBackground ? colors.primary[400] : ""}
        marginTop={marginTop}
        {...props}
      >
        {children}
      </Box>
    </>
  );
};

export default BoxWrap;
