import { create } from "zustand";
import { ChatProps } from "../utils/chat-types";

type SocketStore = {
  dialogsListState: ChatProps[] | any;
  socketConnectionState: any;
  socketRefCurrentState: WebSocket | null;
  selectedChat: ChatProps | undefined; // Add selectedChat state
  setSocketConnectionState: (
    connectionState: any,
    socketRefCurrent: WebSocket | null,
    dialogsList: ChatProps[] | any
  ) => void;
  setSelectedChat: (chat: ChatProps | undefined) => void; // Add setSelectedChat method
  sendNewMessage: (event: any) => void;
};

const useSocketStore = create<SocketStore>((set, get) => ({
  dialogsListState: null,
  socketConnectionState: null,
  socketRefCurrentState: null,
  selectedChat: undefined, // Initialize selectedChat as null
  setSocketConnectionState: (
    connectionState: any,
    socketRefCurrent: WebSocket | null,
    dialogsList: ChatProps[] | any
  ) =>
    set({
      socketConnectionState: connectionState,
      socketRefCurrentState: socketRefCurrent,
      dialogsListState: dialogsList,
    }),
  setSelectedChat: (chat: ChatProps | undefined) => set({ selectedChat: chat }), // Define setSelectedChat method
  sendNewMessage: (event: any) => {
    const socketRefCurrentState = get().socketRefCurrentState;
    if (socketRefCurrentState?.readyState === WebSocket.OPEN) {
      socketRefCurrentState?.send(JSON.stringify(event));
    }
  },
}));

export default useSocketStore;
