import React from "react";
import Sheet from "@mui/joy/Sheet";
import MessagesPane from "./MessagesPane";
import ChatsPane from "./ChatsPane";
import { ChatProps, MessageTypes, IdentityProps } from "../../utils/chat-types";
import useSocketStore from "../../stores/store-socket";
import { useFetchSelfInfo } from "../../api/chat";
import { toast } from "react-toastify";

export default function MyProfile() {
  const selfInfoQuery = useFetchSelfInfo();
  const [self, setSelf] = React.useState<IdentityProps | undefined>();
  const {
    dialogsListState,
    socketConnectionState,
    socketRefCurrentState,
    setSocketConnectionState,
    selectedChat,
  } = useSocketStore();

  const [typing, setTyping] = React.useState<boolean>(false);
  const handleWentOnline = (username: string) => {
    if (dialogsListState) {
      const updatedChats = dialogsListState.map((chat: ChatProps) => {
        if (chat.sender.username === username) {
          return {
            ...chat,
            sender: {
              ...chat.sender,
              online: true,
            },
          };
        }
        return chat;
      });
      setSocketConnectionState(
        socketConnectionState,
        socketRefCurrentState,
        updatedChats
      );
    }
  };

  const handleWentOffline = (username: string) => {
    if (dialogsListState) {
      const updatedChats = dialogsListState.map((chat: ChatProps) => {
        if (chat.sender.username === username) {
          return {
            ...chat,
            sender: {
              ...chat.sender,
              online: false,
            },
          };
        }
        return chat;
      });
      setSocketConnectionState(
        socketConnectionState,
        socketRefCurrentState,
        updatedChats
      );
    }
  };
  // Add a state to keep track of the typing timeout
  const [typingTimeout, setTypingTimeout] = React.useState<any>(null);

  // Function to handle typing events
  const handleIsTyping = (userPk: number) => {
    setTyping(true);

    // Clear the existing typing timeout, if any
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new typing timeout
    const newTypingTimeout = setTimeout(() => {
      setTyping(false);
    }, 5000); // 5 seconds

    setTypingTimeout(newTypingTimeout);
  };

  // Function to handle typing stopped event
  const handleTypingStopped = () => {
    setTyping(false);

    // Clear the typing timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
  };
  React.useEffect(() => {
    if (selfInfoQuery.isSuccess) {
      setSelf(selfInfoQuery.data ?? undefined);
    }
    if (socketRefCurrentState) {
      socketRefCurrentState.onmessage = (e) => {
        const message = JSON.parse(e.data);
        let toastOptions = {
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
          draggable: false,
        };
        // Check the message type and handle accordingly
        switch (message.msg_type) {
          case MessageTypes.GroupTextMessage:
            if (dialogsListState) {
              const updatedChats = dialogsListState.map((chat: ChatProps) => {
                if (chat.type == "group" && chat.sender.id === message.sender) {
                  return {
                    ...chat,
                    unread_count: 2,
                  };
                }
                return chat;
              });

              // Find the index of the chat with the specified sender ID
              const index = updatedChats.findIndex(
                (chat: ChatProps) =>
                  chat.type == "group" && chat.sender.id === message.sender
              );

              if (index !== -1) {
                // Move the chat to the beginning of the array
                const chatToMove = updatedChats.splice(index, 1)[0];
                updatedChats.unshift(chatToMove);
              }

              setSocketConnectionState(
                socketConnectionState,
                socketRefCurrentState,
                updatedChats
              );
            }
            break;

          case MessageTypes.WentOnline:
            handleWentOnline(message.username);
            break;

          case MessageTypes.WentOffline:
            handleWentOffline(message.username);
            break;

          case MessageTypes.ErrorOccurred:
            break;

          case MessageTypes.IsTyping:
            // Handle is typing event
            // Access the necessary data from `message` object
            if (selectedChat && selectedChat.sender.id === message.user_pk) {
              handleIsTyping(message.user_pk);
            }
            break;

          case MessageTypes.TypingStopped:
            // Handle is typing event
            // Access the necessary data from `message` object
            if (selectedChat && selectedChat.sender.id === message.user_pk) {
              handleTypingStopped();
            }
            break;

          case MessageTypes.NewGroupOpen:
            // Handle is typing event
            // Access the necessary data from `message` object
            break;

          default:
            break;
        }
      };
    }
  }, [socketRefCurrentState, selfInfoQuery.isSuccess, dialogsListState]);

  return (
    <Sheet
      sx={{
        bgcolor: "background.body",
        flex: 1,
        width: "100%",
        mx: "auto",
        pt: { xs: "var(--Header-height)", lg: 0 },
        display: "grid",
        gridTemplateColumns: {
          xs: "1fr",
          sm: "minmax(min-content, min(30%, 400px)) 1fr",
        },
      }}
    >
      <Sheet
        sx={{
          position: {
            xs: "fixed",
            sm: "sticky",
          },
          transform: {
            xs: "translateX(calc(100% * (var(--MessagesPane-slideIn, 0) - 1)))",
            sm: "none",
          },
          transition: "transform 0.4s, width 0.4s",
          zIndex: 100,
          width: "100%",
          top: 52,
        }}
      >
        {dialogsListState && <ChatsPane self={self} />}
      </Sheet>
      {selectedChat && <MessagesPane self={self} typing={typing} />}
    </Sheet>
  );
}
