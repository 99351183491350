import * as React from "react";
import GlobalStyles from "@mui/joy/GlobalStyles";
import IconButton from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import { toggleSidebar } from "./utils";
import { useTheme } from "@mui/material";
import { tokens } from "./theme";

export default function Header() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Sheet
      sx={{
        bgcolor: colors.primary[400],
        display: { xs: "flex", lg: "none" },
        alignItems: "center",
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "var(--Header-height)",
        zIndex: 9995,
        py: 1,
        px: 2,
        gap: 1,
        boxShadow: "sm",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Header-height": "52px",
            [theme.breakpoints.up("lg")]: {
              "--Header-height": "0px",
            },
          },
        })}
      />
      <IconButton
        onClick={() => toggleSidebar()}
        variant="outlined"
        color="neutral"
        size="sm"
      >
        <i data-feather="menu" />
      </IconButton>
    </Sheet>
  );
}
