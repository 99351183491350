import React, { useEffect, useRef } from "react";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import FileIcon from "./FileIcon";
import {
  MessageProps,
  MessageTypes,
  IdentityProps,
} from "../../utils/chat-types";
import Grid from "@mui/material/Grid"; // Import Grid component
import useSocketStore from "../../stores/store-socket";
import GetAppIcon from "@mui/icons-material/GetApp"; // Import the download icon
import { BASE_CHAT_URL } from "../../api";

type ChatBubbleProps = MessageProps & {
  variant: "sent" | "received";
  status: any;
  self: IdentityProps | any;
};

export default function ChatBubble({
  self,
  id,
  status,
  read,
  content,
  variant,
  timestamp,
  attachment = undefined,
  sender,
}: ChatBubbleProps) {
  // Ref for the ChatBubble element
  const chatBubbleRef = useRef<HTMLDivElement>(null);
  const isSent = variant === "sent";
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const { sendNewMessage } = useSocketStore();
  const [isHoveredIcon, setIsHoveredIcon] = React.useState(false); // Track hover state for icon
  const { selectedChat } = useSocketStore();
  // Intersection Observer callback function
  const onChatBubbleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (
        entry.isIntersecting &&
        !read &&
        sender.id !== self.id &&
        selectedChat &&
        selectedChat.type == "dialog"
      ) {
        const event = {
          user_pk: sender.id,
          message_id: parseInt(id), // Assuming you have a unique id for each message
          msg_type: MessageTypes.MessageRead,
        };
        sendNewMessage(event);
        status = null;
      }
    });
  };

  useEffect(() => {
    // Intersection Observer instance
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "0px",
      threshold: 0.1, // Trigger the callback when at least 10% of the ChatBubble is visible
    };
    const observer = new IntersectionObserver(
      onChatBubbleIntersection,
      options
    );

    if (chatBubbleRef.current) {
      observer.observe(chatBubbleRef.current);
    }

    return () => {
      if (chatBubbleRef.current) {
        observer.unobserve(chatBubbleRef.current);
      }
    };
  }, [status, sender.id, id]);
  return (
    <Box
      maxWidth="80%"
      minWidth={attachment ? "80%" : "auto"}
      ref={chatBubbleRef}
      onMouseOver={() => {
        // Check if the ChatBubble is not read and not sent by the current user
        if (
          !read &&
          sender.id !== self.id &&
          selectedChat &&
          selectedChat.type == "dialog"
        ) {
          const event = {
            user_pk: sender.id,
            message_id: parseInt(id), // Assuming you have a unique id for each message
            msg_type: MessageTypes.MessageRead,
          };
          sendNewMessage(event);
          status = null;
        }
      }}
      onClick={() => {
        // Check if the ChatBubble is not read and not sent by the current user
        if (
          !read &&
          sender.id !== self.id &&
          selectedChat &&
          selectedChat.type == "dialog"
        ) {
          const event = {
            user_pk: sender.id,
            message_id: parseInt(id), // Assuming you have a unique id for each message
            msg_type: MessageTypes.MessageRead,
          };
          sendNewMessage(event);
          status = null;
        }
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{ mb: 0.25 }}
      >
        <Typography level="body3">{sender.username}</Typography>
        <Typography level="body3">{timestamp}</Typography>
      </Stack>
      {attachment ? (
        <Sheet
          variant="outlined"
          sx={{
            px: 1.75,
            py: 1.25,
            borderRadius: "lg",
            borderTopRightRadius: isSent ? 0 : "lg",
            borderTopLeftRadius: isSent ? "lg" : 0,
          }}
        >
          <Stack
            direction="row"
            spacing={1.5}
            alignItems="center"
            justifyContent="space-between"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "1.5rem" }}>
                <IconButton
                  onClick={() =>
                    window.open(`${BASE_CHAT_URL}${attachment.url}`, "_blank")
                  }
                  variant="plain"
                  color="neutral"
                  onMouseEnter={() => setIsHoveredIcon(true)}
                  onMouseLeave={() => setIsHoveredIcon(false)}
                  sx={{ width: "24px", height: "24px" }}
                >
                  {isHoveredIcon ? (
                    <GetAppIcon
                      sx={{
                        width: 32,
                        height: 40,
                        position: "relative",
                        display: "block",
                        stroke: "#D0D5DD",
                        strokeWidth: 1.5,
                      }}
                    />
                  ) : (
                    <FileIcon fileType={attachment.type} />
                  )}
                </IconButton>
              </div>
              <div>
                <Typography fontSize="sm">{attachment.fileName}</Typography>
                <Typography level="body2">{attachment.size}</Typography>
              </div>
            </div>
            {status}
          </Stack>
        </Sheet>
      ) : (
        <Box
          sx={{ position: "relative" }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Sheet
            color={isSent ? "primary" : "neutral"}
            variant={isSent ? "solid" : "soft"}
            sx={{
              px: 1.25,
              py: 1.25,
              borderRadius: "lg",
              borderTopRightRadius: isSent ? 0 : "lg",
              borderTopLeftRadius: isSent ? "lg" : 0,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={9.9}>
                {content}
              </Grid>
              <Grid item xs={0.1}>
                {status}
              </Grid>
            </Grid>
          </Sheet>
        </Box>
      )}
    </Box>
  );
}
