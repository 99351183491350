export interface IParams {
  client: { name?: string; id: number };
}

export const clientDetailsBreadcrumb = (props: IParams) => {
  const { client } = props;

  return [
    { title: "Home", path: "/" },
    { title: "Kunden", path: "/clients" },
    {
      title: client?.name ? client?.name : "Loading...",
      path: `/clients/${client.id}`,
    },
  ].filter((item) => item.title);
};
