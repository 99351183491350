import React from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Stack from "@mui/joy/Stack";
import Add from "@mui/icons-material/Add";
import Typography from "@mui/joy/Typography";
import Autocomplete from "@mui/joy/Autocomplete";
import { useFetchUserContacts } from "../../api/contacts";
import { IdentityProps, ChatProps } from "../../utils/chat-types";
import { useCreateGroup } from "../../api/chat";
import useSocketStore from "../../stores/store-socket";

export default function NewGroupModal() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [groupName, setGroupName] = React.useState<string>("");
  const [selectedMembers, setSelectedMembers] = React.useState<string[]>([]);
  const usersData = useFetchUserContacts();
  const createGroupMutation = useCreateGroup(); // Rename to createGroupMutation

  const {
    dialogsListState,
    setSocketConnectionState,
    socketConnectionState,
    socketRefCurrentState,
    setSelectedChat,
  } = useSocketStore();

  const userNames = usersData.data?.data
    ? usersData.data.data.map((user: IdentityProps) => user.name)
    : [];

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOpen(false);

    const selectedMemberIds = usersData.data?.data
      ?.filter((user: IdentityProps) => selectedMembers.includes(user.name))
      .map((user: IdentityProps) => user.id);

    if (selectedMemberIds && groupName) {
      try {
        const result = await createGroupMutation.mutateAsync({
          // Use mutateAsync to call the function
          participantIds: selectedMemberIds,
          groupName: groupName,
        });

        const selectedMembersData = usersData.data?.data?.filter(
          (user: IdentityProps) => selectedMemberIds.includes(user.id)
        );

        const newChat: ChatProps = {
          id: `group_${result.group_id}`,
          sender: {
            id: result.group_id,
            username: groupName,
            name: groupName,
            avatar: "",
            online: false,
          },
          lastMessage: `Neuer Gespräch!`,
          timestamp: "Soeben",
          unread: 0,
          lastseen: "",
          type: "group",
          members: selectedMembersData,
        };

        if (dialogsListState) {
          const updatedDialogsList = [newChat, ...dialogsListState];
          setSocketConnectionState(
            socketConnectionState,
            socketRefCurrentState,
            updatedDialogsList
          );
          setSelectedChat(newChat);
        }
      } catch (error) {
        console.error("Error creating group:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="neutral"
        startDecorator={<Add />}
        onClick={() => setOpen(true)}
      >
        Neue Gruppe
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ maxWidth: 500 }}
        >
          <Typography id="basic-modal-dialog-title" component="h2">
            Neue Gruppe erstellen
          </Typography>
          <Typography
            id="basic-modal-dialog-description"
            textColor="text.tertiary"
          >
            Geben Sie die Informationen der Gruppe ein.
          </Typography>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  autoFocus
                  required
                  value={groupName}
                  onChange={(event) => setGroupName(event.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Mitglieder</FormLabel>
                {usersData.isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <Autocomplete
                    disableCloseOnSelect
                    multiple
                    id="tags-default"
                    options={userNames}
                    value={selectedMembers}
                    onChange={(event, newValue) => setSelectedMembers(newValue)}
                    placeholder="Wählen Sie Mitglieder aus..."
                  />
                )}
              </FormControl>
              <Stack direction="row" justifyContent="space-between">
                <Button variant="outlined" onClick={() => setOpen(false)}>
                  Abbrechen
                </Button>
                <Button type="submit">Bestätigen</Button>
              </Stack>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
