import React, { useEffect } from "react";
import ResetPasswordFields from "../fields/reset-password";
import resetPasswordValidation from "../../../validation/resetPassword";
import { Box, Button, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { showErrorAlert, showSuccessAlert } from "../alerts";
import { useResetPassword } from "../../../api/auth";

const ResetPasswordForm = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const resetPasswordMutation = useResetPassword();
  const navigate: NavigateFunction = useNavigate();

  const initialValues = {
    token: "",
    password: "",
    password_confirmation: "",
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get("token");

    if (token) {
      // Update the token value in Formik's initial values
      initialValues.token = token;
    }
  }, []);

  const handleFormSubmit = async (values: any) => {
    try {
      const { token, password, password_confirmation } = values;
      await resetPasswordMutation.mutateAsync({
        token,
        password,
        password_confirmation,
      });

      await showSuccessAlert(`Das Passwort wurde erfolgreich zurückgesetzt!`);
      navigate(`/login`);
    } catch (error: any) {
      const { data } = error.response;
      showErrorAlert(data.message);
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={resetPasswordValidation}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <ResetPasswordFields
            isNonMobile={isNonMobile}
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <Box display="flex" justifyContent="end" m="15px">
            <Button
              size="medium"
              type="submit"
              color="secondary"
              variant="contained"
              sx={{
                fontSize: "1rem",
                textTransform: "none",
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? `Bestätigen....` : `Bestätigen`}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
