import { GridColDef } from "@mui/x-data-grid";

export const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "sap",
    headerName: "SAP",
    flex: 1,
  },
  {
    field: "aquaResponsibleUsers",
    headerName: "Verantwortlicher",
    flex: 1,
  },
  {
    field: "customerOf",
    headerName: "Kunde von",
    flex: 1,
  },
];
