import { userToken, userCookieId } from "../utils/helper";
export const BASE_URL: string =
  process.env.REACT_APP_BASE_BACKEND_URL ?? "http://localhost:8000/api";
export const BASE_CHAT_URL: string =
  process.env.REACT_APP_BASE_CHAT_URL ?? "http://localhost:8001";
export const BASE_CHAT_SOCKET: string =
  process.env.REACT_APP_BASE_CHAT_SOCKET ?? "ws://localhost:8001";
export const token: string | null = userToken;
export const userId: string = userCookieId ?? "0";
// export const roleName: string | null = getRole("_auth_state");
