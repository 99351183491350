import React, { useState, useEffect } from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Stack from "@mui/joy/Stack";
import Edit from "@mui/icons-material/Edit";
import Typography from "@mui/joy/Typography";
import Autocomplete from "@mui/joy/Autocomplete";
import { useFetchUserContacts } from "../../api/contacts";
import { ChatProps, IdentityProps } from "../../utils/chat-types";
import { useEditGroup } from "../../api/chat";
import useSocketStore from "../../stores/store-socket";

type EditGroupModalProps = {
  id: string;
  name: string;
  members: IdentityProps[] | undefined;
};

export default function EditGroupModal({
  id,
  name,
  members,
}: EditGroupModalProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [editedGroupName, setEditedGroupName] = useState<string>(name);
  const [editedMembers, setEditedMembers] = useState<string[]>(
    members ? members.map((member) => member.username) : []
  );

  useEffect(() => {
    // Update the state when the props change
    setEditedGroupName(name);
    setEditedMembers(members ? members.map((member) => member.username) : []);
  }, [name, members]); // Listen for changes in 'name' and 'members' props

  const usersData = useFetchUserContacts(); // Fetch user data
  const editGroup = useEditGroup(); // Initialize the useEditGroup hook

  const userNames = usersData.data?.data
    ? usersData.data.data.map((user: IdentityProps) => user.name)
    : [];

  const {
    setSocketConnectionState,
    socketConnectionState,
    socketRefCurrentState,
    dialogsListState,
    selectedChat,
    setSelectedChat,
  } = useSocketStore();

  const handleMembersChange = (
    event: React.ChangeEvent<{}>,
    newValue: string[]
  ) => {
    setEditedMembers(newValue);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOpen(false);

    // Convert edited member names to their corresponding IDs
    const editedMemberIds = usersData.data?.data
      ?.filter((user: IdentityProps) => editedMembers.includes(user.name))
      .map((user: IdentityProps) => user.id);

    if (editedMemberIds && editedGroupName) {
      try {
        const result = await editGroup.mutateAsync({
          // Use .mutateAsync to await the mutation
          groupId: id,
          newParticipantIds: editedMemberIds,
          newGroupName: editedGroupName,
        });

        if (result.success) {
          if (dialogsListState && members) {
            // Create a new list of UserProps based on the selected member usernames
            const updatedMembers: IdentityProps[] =
              usersData.data?.data
                ?.filter((user: IdentityProps) =>
                  editedMembers.includes(user.name)
                )
                .map((user: IdentityProps) => ({
                  ...user,
                  username: user.name,
                })) || [];

            const updatedDialogsList = dialogsListState.map(
              (dialog: ChatProps) => {
                if (dialog.id === `group_${id}`) {
                  // Update the group's name and members
                  return {
                    ...dialog,
                    sender: {
                      ...dialog.sender,
                      username: editedGroupName,
                      name: editedGroupName,
                    },
                    members: updatedMembers,
                  };
                }
                return dialog;
              }
            );
            setSocketConnectionState(
              socketConnectionState,
              socketRefCurrentState,
              updatedDialogsList
            );
            if (selectedChat)
              setSelectedChat({
                ...selectedChat,
                sender: {
                  ...selectedChat.sender,
                  username: editedGroupName,
                  name: editedGroupName,
                },
                members: updatedMembers,
              });
          }
        }
      } catch (error) {
        console.error("Error editing group:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <Button variant="outlined" color="neutral" onClick={() => setOpen(true)}>
        <Edit />
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ maxWidth: 500 }}
        >
          <Typography id="basic-modal-dialog-title" component="h2">
            Gruppe bearbeiten
          </Typography>
          <Typography
            id="basic-modal-dialog-description"
            textColor="text.tertiary"
          >
            Aktualisieren Sie die Informationen der Gruppe.
          </Typography>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  autoFocus
                  required
                  value={editedGroupName}
                  onChange={(event) => setEditedGroupName(event.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Mitglieder</FormLabel>
                {usersData.isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <Autocomplete
                    disableCloseOnSelect
                    multiple
                    id="tags-default"
                    options={userNames}
                    value={editedMembers}
                    onChange={handleMembersChange}
                    placeholder="Wählen Sie Mitglieder aus..."
                  />
                )}
              </FormControl>
              <Stack direction="row" justifyContent="space-between">
                <Button variant="outlined" onClick={() => setOpen(false)}>
                  Abbrechen
                </Button>
                <Button type="submit">Bestätigen</Button>
              </Stack>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
