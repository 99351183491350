import { useRef, useEffect } from "react";
import axios from "axios";
import ReconnectingWebSocket from "reconnecting-websocket";
import { clearAllCookies, getCookieValue } from "../utils/helper";
import useSocketStore from "../stores/store-socket";
import { useFetchDialogsList } from "../api/chat";
import { BASE_CHAT_SOCKET, BASE_CHAT_URL } from "../api";
export const socketLogout = async () => {
  const selfEndpoint = `${BASE_CHAT_URL}/websocket_logout`;
  try {
    const response = await axios.get(selfEndpoint);
    if (response.status === 200) {
      // Clear the 'session_id' cookie by setting it to an empty string with an expired date
      clearAllCookies();
    } else {
      // Handle logout failure
      console.error("Logout failed");
    }
  } catch (error) {
    // Handle network or other errors
    console.error("Error during logout", error);
  }
};

export const useHandshaking = () => {
  const socketRef = useRef<WebSocket | null>(null);
  const { setSocketConnectionState, socketRefCurrentState } = useSocketStore();
  const useFetchDialogsListQuery = useFetchDialogsList();
  const authStatusCookie = getCookieValue("_auth_state");
  useEffect(() => {
    (() => {
      if (authStatusCookie) {
        const authState = JSON.parse(decodeURIComponent(authStatusCookie));
        if (!getCookieValue("session_id")) {
          // No matter what, try to perform the handshake
          axios
            .get(`${BASE_CHAT_URL}/handshaking`, {
              headers: {
                Authorization: `Bearer ${authState.token}`,
              },
            })
            .then((response) => {
              if (response.data && response.status === 200) {
                const { session_id, csrf_token } = response.data;
                // Store the authentication data in a cookie
                document.cookie = `session_id=${session_id}`;
                document.cookie = `csrf_token=${csrf_token}`;
              } else {
                // Handle login failure
                console.error("Login failed");
              }
            })
            .catch((error) => {
              // Handle network or other errors
              console.error("Error during login", error);
            });
        }
      }
    })();
  }, [
    authStatusCookie,
    setSocketConnectionState,
    socketRefCurrentState,
    useFetchDialogsListQuery.isSuccess,
    useFetchDialogsListQuery.data,
  ]);
};

export const useSocketConnect = () => {
  const socketRef = useRef<WebSocket | null>(null);
  const { setSocketConnectionState, socketRefCurrentState } = useSocketStore();
  const useFetchDialogsListQuery = useFetchDialogsList();
  const authStatusCookie = getCookieValue("_auth_state");
  const sessionID = getCookieValue("session_id");
  useEffect(() => {
    (() => {
      if (sessionID) {
        if (!socketRefCurrentState) {
          socketRef.current = new ReconnectingWebSocket(
            `${BASE_CHAT_SOCKET}/chat_ws?sessionID=${sessionID}`
          ) as WebSocket; // Type assertion to WebSocket
        }
        if (socketRef.current && useFetchDialogsListQuery.isSuccess) {
          socketRef.current.onopen = function (e) {
            setSocketConnectionState(
              socketRef.current?.readyState,
              socketRef.current,
              useFetchDialogsListQuery.data
            );
          };
          socketRef.current.onclose = function (e) {
            setSocketConnectionState(
              socketRef.current?.readyState,
              socketRef.current,
              useFetchDialogsListQuery.data
            );
          };
        }
      }
    })();
  }, [
    sessionID,
    authStatusCookie,
    setSocketConnectionState,
    socketRefCurrentState,
    useFetchDialogsListQuery.isSuccess,
    useFetchDialogsListQuery.data,
  ]);
};
