import React from "react";
import { Grid, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import DataTable from "../shared/table";
import { useDashboardTables } from "../../hooks/useDashboardTables";
import Can from "../../casl/Can";

const Dashboard = () => {
	const tablesData = useDashboardTables();
	return (
		<>
			<Grid
				container
				columnSpacing={2}
				rowSpacing={5}
				mb={2}
				mt={{ xs: 5, lg: 0 }}
			>
				{tablesData.map((table, index) => (
					<Can key={index} I="view" a={table.permission}>
						<Grid item xs={12} lg={6}>
							<Typography variant="h4" fontWeight="bold" mb={2}>
								{table.title}
							</Typography>
							<DataTable
								data={table.rows ?? []}
								fieldsNames={table.columns}
								noDataMessage="Keine zugewiesene Aufgabe für Sie"
								currentPage={table.paginationModel.data.page - 1}
								totalItems={table.paginationModel.data.total}
								itemsPerPage={table.paginationModel.data.pageSize}
								isLoading={table.isLoading}
								parentPath={table?.parentPath}
								boxHeight={75}
								ssrFilter={true}
								ssrSort={true}
								onPageChange={(page) =>
									table.paginationModel.setPagination({
										page: page.page + 1,
										pageSize: page.pageSize,
									})
								}
								onFilterChange={(f) =>
									table.filterModel.setFilter({ items: [f] })
								}
								onSortChange={table.sortModel.setSort}
								customLink={table?.customLink}
							/>
						</Grid>
					</Can>
				))}
			</Grid>
			<Outlet />
		</>
	);
};

export default Dashboard;
