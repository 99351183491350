import * as yup from "yup";

const accountSchema = yup.object().shape({
  email: yup
    .string()
    .email("Ungültige E-Mail")
    .required("E-Mail ist erforderlich!"),
  password: yup
    .string()
    .required("Passwort ist erforderlich!")
    .min(6, "Das Passwort muss mindestens 6 Zeichen lang sein."),
});

export default accountSchema;
