import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CustomButton from "./custom-button";
import React, { FunctionComponent } from "react";
import { saveAs } from "file-saver";

interface DownloadFileButtonProps {
  label: string;
  filePath: string;
  fileName: string;
}

const DownloadFileButton: FunctionComponent<DownloadFileButtonProps> = (
  props
) => {
  const { label, filePath, fileName } = props;
  const handleButtonClick = () => {
    fetch(filePath)
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok, status: ${response.status}`
          );
        }
        return response.blob();
      })
      .then((blob) => {
        if (blob.size > 0) {
          saveAs(blob, fileName);
        } else {
          console.error("The downloaded file is empty.");
        }
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  };
  return (
    <>
      <CustomButton
        label={label}
        startIcon={<CloudDownloadIcon />}
        handleOnClick={handleButtonClick}
      />
    </>
  );
};

export default DownloadFileButton;
