import * as yup from "yup";

const forgotPasswordValidation = yup.object().shape({
  email: yup
    .string()
    .email("Ungültige E-Mail")
    .required("E-Mail ist erforderlich!"),
});

export default forgotPasswordValidation;
