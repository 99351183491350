import React, { useRef } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import Textarea from "@mui/joy/Textarea";
import { IconButton, Stack } from "@mui/joy";
import useScript from "../../utils/useScript";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { MessageTypes } from "../../utils/chat-types";
import useSocketStore from "../../stores/store-socket";

const useEnhancedEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;

export type MessageInputProps = {
  textAreaValue: string;
  setTextAreaValue: (value: string) => void;
  onTextSubmit: () => void;
  onFileSubmitCallback: (fileData: any) => void;
};

export default function MessageInput({
  textAreaValue,
  setTextAreaValue,
  onTextSubmit,
  onFileSubmitCallback,
}: MessageInputProps) {
  const inputFile = useRef<HTMLInputElement | null>(null);
  const { sendNewMessage, selectedChat } = useSocketStore();
  const onFileSubmit = async (file: any) => {
    if (file) {
      try {
        // Notify the parent component about the uploaded file
        onFileSubmitCallback(file); // Call the parent callback function
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };
  const onAtachClick = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      onFileSubmit(event.target.files[0]);
      // Clear the file input value by its id
      const fileInput = document.getElementById(
        "fileInput"
      ) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = "";
      }
    }
  };
  const status = useScript(`https://unpkg.com/feather-icons`);
  const textAreaRef = React.useRef<HTMLDivElement>(null);
  const handleClick = () => {
    if (textAreaValue.trim() !== "") {
      onTextSubmit();
      setTextAreaValue("");
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      if (event.shiftKey) {
        // Insert a new line when Shift + Enter is pressed
        event.preventDefault();
        const { selectionStart, selectionEnd } = event.currentTarget;
        const value = textAreaValue;
        setTextAreaValue(
          value.substring(0, selectionStart) +
            "\n" +
            value.substring(selectionEnd)
        );
      } else {
        // Send the message when Enter is pressed
        event.preventDefault();
        handleClick();
      }
    }
  };

  const handleTyping = () => {
    // Send a typing event to the socket
    if (
      selectedChat &&
      selectedChat.type == "dialog" &&
      selectedChat.sender.online
    ) {
      const event = {
        msg_type: MessageTypes.IsTyping,
        dialog_id: selectedChat.sender.id,
      };
      sendNewMessage(event);
    }
  };

  const handleTypingStopped = () => {
    // Send a typing stopped event to the socket
    if (
      selectedChat &&
      selectedChat.type == "dialog" &&
      selectedChat.sender.online
    ) {
      const event = {
        msg_type: MessageTypes.TypingStopped,
        dialog_id: selectedChat.sender.id,
      };
      sendNewMessage(event);
    }
  };

  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof feather !== "undefined") {
      // @ts-ignore
      feather.replace();
    }
  }, [status]);
  return (
    <Box sx={{ px: 2, pb: 3 }}>
      <FormControl>
        <Textarea
          id="messageTextarea"
          placeholder="Geben Sie hier etwas ein ..."
          aria-label="Message"
          ref={textAreaRef}
          onChange={(e) => {
            setTextAreaValue(e.target.value);
            handleTyping();
          }}
          value={textAreaValue}
          minRows={2}
          maxRows={10}
          endDecorator={
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              flexGrow={1}
              minHeight={40}
            >
              <IconButton
                onClick={onAtachClick}
                variant="plain"
                color="neutral"
                size="md"
              >
                <AttachFileIcon />
              </IconButton>
              <input
                id="fileInput"
                type="file"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <Button onClick={handleClick}>Schicken</Button>
            </Stack>
          }
          onKeyDown={handleKeyDown}
          onKeyUp={handleTypingStopped}
        />
      </FormControl>
    </Box>
  );
}
