import React, { FunctionComponent, Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { PUBLIC_ROUTES } from "./lazy-loading";
import Loader from "../components/shared/loader";
import { useHandshaking, useSocketConnect } from "../configs/socket";

interface MainRoutesProps {}

const MainRoutes: FunctionComponent<MainRoutesProps> = () => {
  useHandshaking();
  useSocketConnect();
  const routes = useRoutes(PUBLIC_ROUTES);
  return <Suspense fallback={<Loader />}>{routes}</Suspense>;
};

export default MainRoutes;
