import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React, { FunctionComponent, useRef } from "react";
import { Box, Button } from "@mui/material";

interface ExcelFileUploadProps {
  onFileUpload: (file: File) => void;
  isFileSelected: boolean;
  identifier?: string;
  accept?: string; // Add accept prop to specify file types
}

const ExcelFileUpload: FunctionComponent<ExcelFileUploadProps> = (props) => {
  const { onFileUpload, isFileSelected, identifier, accept } = props;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      onFileUpload(file);
    }
  };

  return (
    <Box display="flex">
      <Button
        startIcon={<CloudUploadIcon />}
        variant="contained"
        color="secondary"
        size="small"
        sx={{
          fontSize: "1rem",
          textTransform: "none",
        }}
        onClick={handleButtonClick}
      >
        {accept ? "Datei hochladen" : "Excel-Datei hochladen"}
      </Button>
      <input
        ref={fileInputRef}
        key={identifier}
        id={identifier}
        type="file"
        style={{ display: "none" }}
        accept={accept || ".xls, .xlsx"}
        onChange={handleFileChange}
      />
    </Box>
  );
};

export default ExcelFileUpload;
