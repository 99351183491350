import { ApiService } from "./api.service";
import {
  ProductModel,
  CreateProductModel,
  ProductTypesModel,
} from "../models/products/product.model";
import { IResponse } from "../models/response";
import { useQuery, useMutation, MutationFunction } from "@tanstack/react-query";
import { AxiosError } from "axios";

// get products list with pagination
export const useFetchProducts = (
  per_page: number | null = null,
  page = 1,
  filters?: Record<string, unknown>,
  sort?: Record<string, unknown>
) => {
  const queryKey = ["products", per_page, page, filters, sort];

  const { refetch, ...queryResult } = useQuery<
    IResponse<ProductModel[]>,
    AxiosError
  >(queryKey, async () => {
    const params: Record<string, unknown> = {
      page: page,
      per_page: per_page,
      sortField: sort?.field,
      sortDirection: sort?.sort,
      ...filters,
    };

    const productsData = await ApiService.get(`/products`, { params });
    return productsData?.data;
  });
  return { ...queryResult, refetch };
};

//  Fetch prosuct without pagination
export const useFetchProductsWithoutPagination = () => {
  const queryKey = ["products"];
  const { refetch, ...queryResult } = useQuery<
    IResponse<ProductModel[]>,
    AxiosError
  >(queryKey, () => ApiService.get(`/products?pagination=0`));
  return { ...queryResult, refetch };
};

// Get product details
export const useFetchProduct = (id?: string) => {
  const queryKey = ["products", id];
  const { refetch, ...querResult } = useQuery<
    IResponse<ProductModel>,
    AxiosError
  >(queryKey, async () => {
    const productsData = await ApiService.get(`/products/${id}`);
    return productsData;
  });
  return { ...querResult, refetch };
};

// Get product types
export const useFetchProductTypes = () => {
  const queryKey = ["product-types"];
  const { refetch, ...queryResult } = useQuery<
    IResponse<ProductTypesModel[]>,
    AxiosError
  >(queryKey, async () => {
    const productTypes = await ApiService.get(`/product-types`);
    return productTypes;
  });
  return { ...queryResult, refetch };
};

// Create new Product
export const useCreateProduct = () => {
  const createProduct: MutationFunction<
    IResponse<CreateProductModel>,
    FormData
  > = async (formData) => {
    // Make the API request using the formData
    const response = await ApiService.postFormData(`/products`, formData);
    return response.data;
  };
  return useMutation(createProduct);
};

// update Product details
export const useUpdateProduct = (productId?: string) => {
  const updateProduct: MutationFunction<
    IResponse<CreateProductModel>,
    FormData
  > = async (formData) => {
    const response = await ApiService.postFormData(
      `/products/${productId}?_method=PATCH`,
      formData
    );
    return response;
  };
  return useMutation(updateProduct);
};
