import { ApiService } from "./api.service";
import { IResponse } from "../models/response";
import { MutationFunction, useMutation, useQuery } from "@tanstack/react-query";
import {
  ComponentsModel,
  ComponentDetailsModel,
  SystemSeparatorComponent,
  HardnessDeviceControlComponent,
  DosingPumpComponent,
  WaterSofteningSystemComponent,
  ReverseOsmosisSystemComponent,
  FilterComponent,
  ComponentOptionsModel,
  CorrosionMeasuringSectionComponent,
  DesalinationComponent,
  UVSystemComponent,
  MistEliminatorComponent,
  ComponentFormModel,
  ComponentAuditsModel,
  ProbenahmestelleComponent,
} from "../models/components/components.model";
import { InfoModel } from "../models/info.model";
import { useContext } from "react";
import { AbilityContext } from "../casl/Can";


// Get list of components
export const useFetchComponents = (
  id: string,
  per_page: number,
  page: number,
  filters?: Record<string, unknown>,
  sort?: Record<string, unknown>
) => {
  const queryKey = [
    "plants",
    id,
    "components",
    per_page,
    page,
    JSON.stringify(filters),
    sort,
  ];
  const { refetch, ...queryResult } = useQuery<
    IResponse<ComponentsModel[]>,
    Error
  >(queryKey, async () => {
    const params = {
      page: page,
      per_page: per_page,
      sortField: sort?.field,
      sortDirection: sort?.sort,
      ...filters,
    };
    const subTasksData = await ApiService.get(`/plants/${id}/components`, {
      params,
    });
    return subTasksData.data;
  });

  return { ...queryResult, refetch };
};

// Get components type
export const useFetchComponentsType = () => {
  const queryKey = ["component-types"];
  return useQuery<IResponse<InfoModel[]>, Error>(queryKey, async () => {
    try {
      const componentsData = await ApiService.get(`/component-types`);
      return componentsData?.data;
    } catch (error) {
      throw error;
    }
  });
};

// Get components options
export const useFetchComponentOptions = () => {
  const queryKey = ["component-options"];
  return useQuery<ComponentOptionsModel, Error>(queryKey, async () => {
    try {
      const componentsData = await ApiService.get(`/component-options`);
      return componentsData?.data;
    } catch (error) {
      throw error;
    }
  });
};

// Create component
export const useCreateComponent = (plantId: string) => {
  const createComponent: MutationFunction<IResponse<any>, FormData> = async (
    formData
  ) => {
    const response = await ApiService.postFormData(
      `/plants/${plantId}/components`,
      formData
    );
    return response.data;
  };
  return useMutation(createComponent);
};
////------------------------------------------------------------
// Get component type

export const useFetchComponentDetails = (componentId?: string) => {
  const queryKey = ["components", componentId];
  return useQuery<ComponentDetailsModel, Error>(queryKey, async () => {
    try {
      const componentsData = await ApiService.get(`/components/${componentId}`);
      return componentsData?.data;
    } catch (error) {
      throw error;
    }
  });
};

////------------------------------------------------------------
// Get component type

export const useFetchComponentAudits = (
  componentId: string,
  per_page: number,
  page: number
) => {
  const queryKey = ["components", componentId, "audits"];
  return useQuery<IResponse<ComponentAuditsModel[]>, Error>(
    queryKey,
    async () => {
      try {
        const componentAudits = await ApiService.get(
          `/components/${componentId}/audits?per_page=${per_page}&page=${page}`
        );
        return componentAudits?.data;
      } catch (error) {
        throw error;
      }
    }
  );
};

////------------------------------------------------------------
/// Delete Component
export const deleteComponent = async (componentId?: string) => {
  try {
    const response = await ApiService.delete(`/components/${componentId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

////------------------------------------------------------------
// update component
export const useUpdateComponent = (id?: string) => {
  const UpdateComponent: MutationFunction<IResponse<any>, any> = async (
    formData
  ) => {
    const response = await ApiService.postFormData(
      `/components/${id}?_method=PATCH`,
      formData
    );
    return response;
  };
  return useMutation(UpdateComponent);
};


export const useFetchCurrentUserTasks = (params: { user_type: "other" | 'self', page?: number, itemsPerPage: number, filters?: Record<string, unknown>, sort?: Record<string, unknown> }) => {
  const queryKey = [`user-current-tasks-${params.user_type}`, params];
  const ability = useContext(AbilityContext);
  const componentsData = () => {
    if(ability.can('view', params.user_type === 'other' ? 'Other Activities' : 'Self Activities')) {
      return ApiService.get(`/today-or-prior-activities`, { params })
    }
    else {
      return  null
    }

    } 
  return useQuery({
    queryFn: componentsData,
    queryKey: queryKey,
    enabled: ability.can('view', params.user_type === 'other' ? 'Other Activities' : 'Self Activities'),
    select: (data) => data.data
  });
}

export const useFetchUpComingUserTasks = (params: { user_type: "other" | 'self', page?: number, itemsPerPage: number, filters?: Record<string, unknown>, sort?: Record<string, unknown> }) => {
  const queryKey = [`user-upcoming-tasks-${params.user_type}`, params];
  const ability = useContext(AbilityContext); 

  const componentsData = () => {
    if(ability.can('view', params.user_type === 'other' ? 'Other Activities' : 'Self Activities')) {
      return  ApiService.get(`/upcoming-activities`, { params });
    }
    else return null
  }
  return useQuery({
    queryFn: componentsData,
    queryKey: queryKey,
    enabled: ability.can('view', params.user_type === 'other' ? 'Other Activities' : 'Self Activities'),
    select: (data) => data.data
  });
}

export const useFetchDisturbancesClient = (params: { page?: number, itemsPerPage: number, filters?: Record<string, unknown>, sort?: Record<string, unknown> }) => {
  const queryKey = [`client-disturbances-issues`, params];
  const ability = useContext(AbilityContext);
  const componentsData = () => {
    if (ability.can('view', 'Disturbances Client')) {
      return ApiService.get(`/client-disturbances`, { params })
    }
    else {
      return null
    }
  };
  return useQuery({
    queryFn: componentsData,
    queryKey: queryKey,
    enabled: ability.can('view', 'Disturbances Client'),
  });
}

export const useFetchResovledTasks = (params: { page?: number, itemsPerPage: number, filters?: Record<string, unknown>, sort?: Record<string, unknown> }) => {
  const queryKey = [`client-resolved-tasks`, params];
  const ability = useContext(AbilityContext);
  const componentsData = () => {
    if(ability.can('view', 'Resolved Tasks Client')) {
      return ApiService.get(`/client-resolved-tasks`, { params });
    }
    else {
      return null
    }
  }
  return useQuery({
    queryFn: componentsData,
    queryKey: queryKey,
    select: (data) => data.data,
    enabled: ability.can('view', 'Resolved Tasks Client')
  });
}