import * as yup from "yup";

const resetPasswordValidation = yup.object().shape({
  token: yup.string().required("Token is required"),
  password: yup
    .string()
    .required("Passwort ist erforderlich!")
    .min(6, "Das Passwort muss mindestens 6 Zeichen lang sein."),
  password_confirmation: yup
    .string()
    .required("Passwortbestätigung ist erforderlich!")
    .test(
      "password-match",
      "Die Passwörter stimmen nicht überein!",
      function (value) {
        const { password } = this.parent;
        return password === value || value === null;
      }
    ),
});

export default resetPasswordValidation;
