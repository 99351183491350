import * as React from "react";
import Stack from "@mui/joy/Stack";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { Box, Chip, IconButton } from "@mui/joy";
import List from "@mui/joy/List";
import ChatListItem from "./ChatListItem";
import { ChatProps, IdentityProps } from "../../utils/chat-types";
import { toggleMessagesPane } from "../../utils/chat-helper";
import useScript from "../../utils/useScript";
import useSocketStore from "../../stores/store-socket";
import SearchInput from "./SearchInput";
import NewGroupModal from "./NewGroupModal";

const useEnhancedEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;
type ChatsPaneProps = {
  self: IdentityProps | any;
};
export default function ChatsPane({ self }: ChatsPaneProps) {
  const { dialogsListState } = useSocketStore();

  const status = useScript(`https://unpkg.com/feather-icons`);

  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof feather !== "undefined") {
      // @ts-ignore
      feather.replace();
    }
  }, [status]);

  return (
    <Sheet
      sx={{
        bgcolor: "background.body",
        borderRight: "1px solid",
        borderColor: "divider",
        height: "calc(100dvh - var(--Header-height))",
        overflowY: "auto",
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center" p={2} pb={1.5}>
        <Typography
          fontSize={{ xs: "md", md: "lg" }}
          fontWeight="lg"
          endDecorator={
            <Chip
              variant="outlined"
              color="neutral"
              size="sm"
              slotProps={{ root: { component: "span" } }}
            >
              {dialogsListState.length}
            </Chip>
          }
          sx={{ mr: "auto" }}
        >
          Gespräche
        </Typography>

        <NewGroupModal></NewGroupModal>

        <IconButton
          variant="outlined"
          aria-label="edit"
          color="neutral"
          size="sm"
          onClick={() => {
            toggleMessagesPane();
          }}
          sx={{ display: { sm: "none" } }}
        >
          <i data-feather="x" />
        </IconButton>
      </Stack>

      <Box px={2} pb={1.5}>
        <SearchInput></SearchInput>
      </Box>
      <List
        sx={{
          py: 0,
          "--ListItem-paddingY": "0.75rem",
          "--ListItem-paddingX": "1rem",
        }}
      >
        {dialogsListState.map((chat: ChatProps) => (
          <ChatListItem
            key={chat.id}
            {...chat}
            unread={chat.unread}
            lastseen={chat.lastseen}
            lastMessage={chat.lastMessage}
            timestamp={chat.timestamp}
            type={chat.type}
            members={chat.members ?? undefined}
            self={self}
          />
        ))}
      </List>
    </Sheet>
  );
}
