import * as yup from "yup";

const setPasswordValidation = yup.object().shape({
  email: yup
    .string()
    .email("Ungültige E-Mail")
    .required("E-Mail ist erforderlich!"),
  password: yup
    .string()
    .required("Passwort ist erforderlich!")
    .min(6, "Das Passwort muss mindestens 6 Zeichen lang sein."),
  password_confirmation: yup
    .string()
    .required("Passwortbestätigung ist erforderlich!")
    .test(
      "password-match",
      "Die Passwörter stimmen nicht überein!",
      function (value) {
        const { password } = this.parent;
        return password === value || value === null;
      }
    ),
});

export default setPasswordValidation;
