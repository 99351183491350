import AddIcon from "@mui/icons-material/Add";
import Can from "../../casl/Can";
import DataTable from "../shared/table";
import Header from "../shared/header";
import LinkButton from "../shared/link-button";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { columns } from "./users.columns";
import { useFetchUsers } from "../../api/users";
import { transformFilterItemToFilterObject } from "../../utils/helper";
import {
  GridFilterItem,
  GridPaginationModel,
  GridSortItem,
} from "@mui/x-data-grid";

interface UsersListProps {}

const UsersList: FunctionComponent<UsersListProps> = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  // Filter
  const [filters, setFilters] = useState({});
  // Sort
  const [sort, setSort] = useState({});
  // Get Users list
  const {
    data: usersData,
    isLoading,
    isError,
    refetch,
  } = useFetchUsers(itemsPerPage, currentPage, filters, sort);
  const users = usersData?.data; // Extract data from the 'data' property
  const userTotalItems = usersData?.pager?.total;

  useEffect(() => {
    refetch();
  }, [currentPage, refetch]);

  const handlePageChange = (newPage: GridPaginationModel) => {
    // Fetch new data from the server based on the new page
    setCurrentPage(newPage.page + 1);
    setItemsPerPage(newPage.pageSize);
  };

  const handleFilterModelChange = (filterItem: GridFilterItem) => {
    const filterObject = transformFilterItemToFilterObject(filterItem);
    setFilters(filterObject);
  };

  const handleSortModelChange = (sortItem: GridSortItem) => {
    setSort(sortItem);
  };

  return (
    <>
      {!isError && (
        <Box
          sx={{
            marginTop: "60px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header title="Benutzer" />
            <Can I="create" a="User">
              <LinkButton
                label="Benutzer erstellen"
                startIcon={<AddIcon />}
                to="/users/create"
              />
            </Can>
          </Box>
          <Can I="view" a="Users">
            <Box marginTop="40px">
              <DataTable
                parentPath="users"
                data={users ?? []}
                fieldsNames={columns}
                boxHeight={77}
                onPageChange={handlePageChange}
                currentPage={currentPage - 1}
                totalItems={userTotalItems}
                itemsPerPage={itemsPerPage}
                ssrFilter={true}
                isLoading={isLoading}
                onFilterChange={handleFilterModelChange}
                onSortChange={handleSortModelChange}
                ssrSort={true}
              />
            </Box>
          </Can>
        </Box>
      )}
    </>
  );
};

export default UsersList;
