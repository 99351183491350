import React from "react";
import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../layout/theme";

type Props = {
  title: string;
  subtitle?: string;
};
const Header = ({ title, subtitle }: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <>
      <Box mb="10px">
        <Typography variant="h3" color={colors.grey[100]} fontWeight="bold">
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="h5"
            color={colors.blueAccent[300]}
            fontWeight="bold"
            p="1em"
          >
            {subtitle}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default Header;
