import * as React from "react";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import AvatarWithStatus from "./AvatarWithStatus";
import ChatBubble from "./ChatBubble";
import MessageInput from "./MessageInput";
import MessagesPaneHeader from "./MessagesPaneHeader";
import CircularProgress from "@mui/joy/CircularProgress";
import {
  ChatProps,
  MessageProps,
  MessageTypes,
  IdentityProps,
} from "../../utils/chat-types";
import { useFetchDialogMessages, useUploadFile } from "../../api/chat";
import useSocketStore from "../../stores/store-socket";
import CheckIcon from "@mui/icons-material/Check";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { toast } from "react-toastify";
import Avatar from "@mui/joy/Avatar";
import { UseMutationResult } from "@tanstack/react-query";

type MessagesPaneProps = {
  self: IdentityProps | any;
  typing: boolean;
};

export default function MessagesPane({ self, typing }: MessagesPaneProps) {
  const {
    dialogsListState,
    socketConnectionState,
    socketRefCurrentState,
    setSocketConnectionState,
    sendNewMessage,
    selectedChat,
  } = useSocketStore();

  const dialogMessagesQuery = useFetchDialogMessages(
    selectedChat?.id,
    selectedChat?.type
  );

  const [chatMessages, setChatMessages] = React.useState<MessageProps[]>([]);
  const [textAreaValue, setTextAreaValue] = React.useState("");
  // Correctly destructure the `uploadFile` function from the result of `useUploadFile`
  const uploadFileMutation: UseMutationResult<any, unknown, File, unknown> =
    useUploadFile();
  const [uploading, setUploading] = React.useState(false);

  function getMessageStatusIcon(message: MessageProps) {
    const isGroupChat = selectedChat && selectedChat.type === "group";
    const isMessageFromSelf = message.sender.username === self.username;
    const isMessageRead = message.read;
    const isChatOnline = selectedChat && selectedChat.sender.online;
    const isLastSeenBeforeMessage =
      selectedChat && selectedChat.lastseen >= message.timestamp;

    if (isGroupChat) {
      if (!isMessageRead && !isMessageFromSelf) {
        return <AutoAwesomeIcon />;
      } else if (isMessageFromSelf) {
        return <CheckIcon />;
      } else {
        return null;
      }
    } else if (isMessageFromSelf) {
      if (isMessageRead) {
        return <DoneAllIcon style={{ color: "aqua" }} />;
      } else if (isChatOnline || isLastSeenBeforeMessage) {
        return <DoneAllIcon />;
      } else {
        return <CheckIcon />;
      }
    } else if (!isMessageRead) {
      return <AutoAwesomeIcon />;
    } else {
      return null;
    }
  }

  const handleWentOnline = (username: string) => {
    if (chatMessages) {
      const updatedMessages = chatMessages.map((message: MessageProps) => {
        if (message.sender.username === username) {
          return {
            ...message,
            sender: {
              ...message.sender,
              online: true,
            },
          };
        }
        return message;
      });
      setChatMessages(updatedMessages);
    }
  };

  const handleWentOffline = (username: string) => {
    if (chatMessages) {
      const updatedMessages = chatMessages.map((message: MessageProps) => {
        if (message.sender.username === username) {
          return {
            ...message,
            sender: {
              ...message.sender,
              online: false,
            },
          };
        }
        return message;
      });
      setChatMessages(updatedMessages);
    }
  };

  const handleFileSubmit = async (file: any) => {
    if (selectedChat && file) {
      try {
        setUploading(true);
        const fileData: any = await uploadFileMutation.mutateAsync(file); // Use `await` to wait for the Promise to resolve
        if (fileData) {
          const fileType = fileData.name.split(".").pop();
          // Handle the uploaded file data, e.g., send it as a message
          const newId = Math.floor(Math.random() * -1000000);
          const newIdString = newId.toString();
          if (socketConnectionState) {
            setTimeout(() => {
              if (selectedChat.type == "dialog") {
                const event = {
                  user_pk: selectedChat.sender.id,
                  msg_type: MessageTypes.FileMessage,
                  random_id: newId,
                  file_id: fileData.id,
                };
                sendNewMessage(event);
              } else {
                const event = {
                  group_id: selectedChat.sender.id,
                  msg_type: MessageTypes.GroupFileMessage,
                  random_id: newId,
                  file_id: fileData.id,
                };
                sendNewMessage(event);
              }
              // Update chat messages with the new file message
              setChatMessages([
                ...(chatMessages ?? []),
                {
                  id: newIdString,
                  sender: self,
                  content: "",
                  attachment: fileData
                    ? {
                        id: fileData.id,
                        fileName: fileData.name,
                        type: fileType,
                        size: Math.round(fileData.size / 1024) + "KB",
                        url: fileData.url,
                      }
                    : undefined,
                  timestamp: "Soeben",
                },
              ]);
              setUploading(false);
            }, 1000);
          }
        }
      } catch (error) {
        // Handle any errors that may occur during the upload or processing
      }
    }
  };

  React.useEffect(() => {
    if (dialogMessagesQuery.isSuccess) {
      setChatMessages(dialogMessagesQuery.data ?? []);
    }
    // Check if there are unread messages
    if (chatMessages && selectedChat && selectedChat.type == "dialog") {
      chatMessages.forEach((message) => {
        if (!message.read && message.sender.id != self.id) {
          const event = {
            user_pk: message.sender.id,
            message_id: parseInt(message.id), // Assuming you have a unique id for each message
            msg_type: MessageTypes.MessageRead,
          };
          sendNewMessage(event);
        }
      });
    }
    if (socketRefCurrentState) {
      socketRefCurrentState.onmessage = (e) => {
        const message = JSON.parse(e.data);
        let toastOptions = {
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
          draggable: false,
        };
        // Check the message type and handle accordingly
        switch (message.msg_type) {
          case MessageTypes.WentOnline:
            // Handle went online event
            // Access the necessary data from `message` object
            handleWentOnline(message.username);
            break;
          case MessageTypes.NewGroupOpen:
            // Handle is typing event
            // Access the necessary data from `message` object
            break;
          case MessageTypes.WentOffline:
            // Handle went offline event
            // Access the necessary data from `message` object
            handleWentOffline(message.username);
            break;

          case MessageTypes.MessageIdCreated:
            // Handle message ID created event
            // Access the necessary data from `message` object
            // Move the chat with the sender number to the first position
            if (dialogsListState && selectedChat) {
              // Sort the chats so that the chat with the sender number is at the top
              const updatedChats = dialogsListState.sort(
                (a: ChatProps, b: ChatProps) =>
                  a.id === selectedChat.id ? -1 : 1
              );
              selectedChat.lastMessage = updatedChats[0].lastMessage =
                message.msg;
              selectedChat.timestamp = updatedChats[0].timestamp = new Date(
                message.sent * 1000
              ).toLocaleString();
              setSocketConnectionState(
                socketConnectionState,
                socketRefCurrentState,
                updatedChats
              );
            }
            break;

          case MessageTypes.GroupMessageIdCreated:
            // Handle message ID created event
            // Access the necessary data from `message` object
            // Move the chat with the sender number to the first position
            if (dialogsListState && selectedChat) {
              // Sort the chats so that the chat with the sender number is at the top
              const updatedChats = dialogsListState.sort(
                (a: ChatProps, b: ChatProps) =>
                  a.id === selectedChat.id ? -1 : 1
              );
              selectedChat.lastMessage = updatedChats[0].lastMessage =
                message.msg;
              selectedChat.timestamp = updatedChats[0].timestamp = new Date(
                message.sent * 1000
              ).toLocaleString();
              setSocketConnectionState(
                socketConnectionState,
                socketRefCurrentState,
                updatedChats
              );
            }
            break;

          case MessageTypes.NewUnreadCount:
            if (dialogsListState && selectedChat) {
              const updatedChats = dialogsListState.map((chat: ChatProps) => {
                if (selectedChat.sender.id === message.sender) {
                  return {
                    ...chat,
                    unread_count: message.unread_count,
                  };
                }
                return chat;
              });

              // Find the index of the chat with the specified sender ID
              const index = updatedChats.findIndex(
                (chat: ChatProps) => selectedChat.sender.id === message.sender
              );

              if (index !== -1) {
                // Move the chat to the beginning of the array
                const chatToMove = updatedChats.splice(index, 1)[0];
                updatedChats.unshift(chatToMove);
              }

              setSocketConnectionState(
                socketConnectionState,
                socketRefCurrentState,
                updatedChats
              );
            }
            break;

          case MessageTypes.ErrorOccurred:
            // Handle error event
            // Access the necessary data from `message` object
            break;
        }
      };
    }
  }, [
    dialogMessagesQuery.isSuccess,
    dialogMessagesQuery.data,
    socketRefCurrentState,
  ]);

  function LoaderOverlay() {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999,
          paddingLeft: "var(--Sidebar-width)",
        }}
      >
        <CircularProgress size="lg" />
      </Box>
    );
  }

  return (
    <Sheet
      sx={{
        bgcolor: "background.body",
        height: { xs: "calc(100dvh - var(--Header-height))", lg: "100dvh" },
        display: "flex",
        flexDirection: "column",
      }}
    >
      {uploading && <LoaderOverlay />}
      <MessagesPaneHeader typing={typing} />
      <Box
        sx={{
          display: "flex",
          flex: 1,
          minHeight: 0,
          px: 2,
          py: 2.5,
          overflowY: "scroll",
          flexDirection: "column-reverse",
        }}
      >
        {self && (
          <Stack spacing={2} justifyContent="flex-end">
            {chatMessages.map((message: MessageProps, index: number) => {
              const isYou = message.sender.username === self.username;
              return (
                <Stack
                  key={index}
                  direction="row"
                  spacing={2}
                  flexDirection={isYou ? "row-reverse" : "row"}
                >
                  {message.sender.username !== self.username &&
                    selectedChat &&
                    selectedChat.type == "dialog" && (
                      <AvatarWithStatus
                        online={selectedChat.sender.online}
                        src={message.sender.avatar}
                      />
                    )}
                  {message.sender.username !== self.username &&
                    selectedChat &&
                    selectedChat.type == "group" && (
                      <Avatar alt={message.sender.username} />
                    )}
                  <ChatBubble
                    status={getMessageStatusIcon(message)}
                    variant={isYou ? "sent" : "received"}
                    self={self}
                    {...message}
                  />
                </Stack>
              );
            })}
          </Stack>
        )}
      </Box>

      {self && (
        <MessageInput
          textAreaValue={textAreaValue}
          setTextAreaValue={setTextAreaValue}
          onTextSubmit={() => {
            if (selectedChat) {
              const newId = Math.floor(Math.random() * -1000000);
              const newIdString = newId.toString();
              const selfInfo = self as IdentityProps;
              if (selectedChat.type == "dialog") {
                const event = {
                  user_pk: selectedChat.sender.id,
                  msg_type: MessageTypes.TextMessage,
                  random_id: newId,
                  text: textAreaValue,
                  sender: selfInfo.id,
                  receiver: selectedChat.sender.id,
                  sender_username: selectedChat.sender.username,
                };
                // Call sendNewMessage from the Zustand store to send the event
                sendNewMessage(event);
              } else {
                const event = {
                  group_id: selectedChat.sender.id,
                  msg_type: MessageTypes.GroupTextMessage,
                  random_id: newId,
                  text: textAreaValue,
                };
                // Call sendNewMessage from the Zustand store to send the event
                sendNewMessage(event);
              }
              setChatMessages([
                ...(chatMessages ?? []),
                {
                  id: newIdString,
                  sender: self,
                  content: textAreaValue,
                  timestamp: "Soeben",
                  attachment: undefined,
                },
              ]);
            }
          }}
          onFileSubmitCallback={handleFileSubmit}
        />
      )}
    </Sheet>
  );
}
