import axios, { AxiosError, AxiosResponse } from "axios";
import { BASE_URL, token } from "./index";
import { PermissionsData } from "../utils/app-types";
import { useQuery } from "@tanstack/react-query";

// Get list of permissions
export const useFetchPermissions = () => {
  const queryKey = ["permissions"];
  const { refetch, ...queryResult } = useQuery<PermissionsData, AxiosError>(
    queryKey,
    async () => {
      const response: AxiosResponse<PermissionsData> = await axios.get(
        `${BASE_URL}/permissions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );
      return response.data;
    }
  );
  return { ...queryResult, refetch };
};

// Get users' permission
export const fetchUserPermissions = async (token: string | null) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  try {
    const response: AxiosResponse<PermissionsData> = await axios.get(
      `${BASE_URL}/get-user-permissions`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user permissions.");
  }
};
