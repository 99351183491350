import { create } from "zustand";
import { PersistStorage, persist } from "zustand/middleware";
import { ModeState } from "../utils/app-types";

type Mode = "dark" | "light"; // Define the Mode type

export interface ExtendedModeState extends ModeState {
  notificationEnabled: boolean;
  enableNotification: () => void;
}

// Define a custom storage adapter
const customStorage: PersistStorage<ExtendedModeState> = {
  getItem: (key) => {
    const value = localStorage.getItem(key);
    return value !== null ? JSON.parse(value) : null;
  },
  setItem: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  removeItem: (key) => {
    localStorage.removeItem(key);
  },
};

export const useModeStore = create<ExtendedModeState>()(
  persist(
    (set, get) => ({
      mode: (localStorage.getItem("joy-mode") as Mode) || "dark",
      notificationEnabled:
        localStorage.getItem("notification-enabled") !== "false",
      enableNotification: () =>
        set((state) => {
          const newNotificationEnabled = !state.notificationEnabled;
          localStorage.setItem(
            "notification-enabled",
            String(newNotificationEnabled)
          );
          return { notificationEnabled: newNotificationEnabled };
        }),
      toggleMode: () =>
        set((state) => {
          const newMode = state.mode === "light" ? "dark" : "light";
          localStorage.setItem("joy-mode", newMode);
          return { mode: newMode };
        }),
    }),
    {
      name: "setting-storage",
      // Use the custom storage adapter
      storage: customStorage,
    }
  )
);
