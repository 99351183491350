import { useQuery } from "@tanstack/react-query";
import { ApiService } from "./api.service";
import { IResponse } from "../models/response";
import { userId } from "./index";
import { NotificationModel } from "../models/notifications/notification.model";
import { AxiosError } from "axios";

// Set Firebase Device Token
export const setFirebaseDeviceToken = (firebaseToken: string) => {
  const id = userId;
  const requestBody = {
    device_token: firebaseToken,
  };
  const url = `/users/${id}/device-token`;
  try {
    const data = ApiService.patch(url, requestBody);
    return data;
  } catch (error) {
    throw error;
  }
};

// Disable / Enable Notifications
export const updateNotificationSettings = async (enabled: boolean) => {
  const id = userId;
  const requestBody = {
    is_notification_enabled: enabled ? true : false,
  };
  const url = `/users/${id}/notification-settings`;
  try {
    const data = await ApiService.patch(url, requestBody);
    return data;
  } catch (error) {
    throw error;
  }
};

// Update Notification To Be Read Or Received
export const setNotificationAsReadOrReceived = async (
  notificationId?: string,
  readFlag?: boolean
) => {
  const id = userId;
  const flag = readFlag ? "read" : "received";
  if (notificationId) {
    const url = `/users/${id}/notifications/${notificationId}/${flag}`;
    try {
      const data = await ApiService.patch(url, {});
      return data;
    } catch (error) {
      throw error;
    }
  }
};

// Get Unread Notification Count
export const useFetchUnreadNotificationsCount = () => {
  const id = userId;
  const queryKey = ["unread-notifications-count"];
  const { refetch, ...queryResult } = useQuery<IResponse<number>, AxiosError>(
    queryKey,
    async () => {
      const url = `/users/${id}/unread-notifications-count`;
      const unreadNotificationsCount = await ApiService.get(url);
      return unreadNotificationsCount;
    }
  );
  return { ...queryResult, refetch };
};

// Get list of notifications
export const useFetchNotifications = (
  userId?: string,
  page = 1,
  itemsPerPage = 10,
  id?: number,
  filters?: Record<string, unknown>,
  sort?: Record<string, unknown>
) => {
  const queryKey = id
    ? ["notifications", id]
    : [
        "notifications",
        userId,
        page,
        itemsPerPage,
        JSON.stringify(filters),
        sort,
      ];

  const { refetch, ...queryResult } = useQuery<
    IResponse<NotificationModel[]>,
    AxiosError
  >(queryKey, async () => {
    const url = id
      ? `/users/${userId}/notifications${id}`
      : `/users/${userId}/notifications`;

    const params = {
      page,
      per_page: itemsPerPage,
      sortField: sort?.field,
      sortDirection: sort?.sort,
      ...filters,
    };
    const notificationsData = await ApiService.get(url, { params });
    const { data } = notificationsData;
    return data;
  });
  return { ...queryResult, refetch };
};

// Mark All Notifications As Read
export const setAllNotificationsAsRead = async () => {
  const id = userId;
  const url = `/users/${id}/notifications/mark-all-as-read`;
  try {
    const data = await ApiService.patch(url);
    return data;
  } catch (error) {
    throw error;
  }
};
