import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material";
import { tokens } from "../layout/theme";
const Loader = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <div>
      <Backdrop
        sx={{
          color: colors.blueAccent[300],
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={true}
        transitionDuration={500}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Loader;
