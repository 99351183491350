import React from "react";
import { Box } from "@mui/material";
import RenderTextField from "../render-text-field";

type FormFieldsProps = {
  isNonMobile: boolean;
  values: any;
  errors: any;
  touched: any;
  handleBlur: any;
  handleChange: any;
};

const ForgotPasswordFields: React.FC<FormFieldsProps> = ({
  isNonMobile,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
}) => {
  return (
    <Box
      display="grid"
      gap="30px"
      gridTemplateColumns="repeat(4, minmax(0,1fr))"
      sx={{
        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
      }}
    >
      <RenderTextField
        name="email"
        label="Email"
        value={values.email}
        error={!!touched.email && !!errors.email}
        helperText={touched.email && errors.email}
        handleBlur={handleBlur}
        handleChange={handleChange}
        span="4"
      />
    </Box>
  );
};

export default ForgotPasswordFields;
