import { Box, List, ListItem, ListItemText, Paper, Typography, useTheme } from '@mui/material';
import React from 'react';
import { tokens } from '../layout/theme';

interface FileCategory {
  germanTitle: string;
  englishDescription: string;
  fileUrl?: string; // fileUrl is optional for top-level categories
}

const industriwasserCategories: FileCategory[] = [
  { germanTitle: 'Absalzplatten/Bypass VDI 6044', englishDescription: 'desaltation_plate', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'Chemie', englishDescription: 'chemistry', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'Enthärtungsanlagen', englishDescription: 'softening_plant', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'Filter', englishDescription: 'filter', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'Pumpen', englishDescription: 'pumps', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'Sprinkler', englishDescription: 'sprinkler', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'Umkehrosmose', englishDescription: 'reverse_osmosis', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'Sonstiges', englishDescription: 'miscellaneous', fileUrl: '/downloads/dummy.pdf' },
];

const coraconCategories: FileCategory[] = [
  { germanTitle: 'WTF Geothermie', englishDescription: 'geothermal_energy', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'WTF Solarthermie', englishDescription: 'solar_energy', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'WTF Gebäude- und Anlagentechnik', englishDescription: 'building_technology', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'Heizung', englishDescription: 'heater', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'Superkonzentrate', englishDescription: 'super_concentrated', fileUrl: '/downloads/dummy.pdf' },
  { germanTitle: 'Sonstiges', englishDescription: 'miscellaneous', fileUrl: '/downloads/dummy.pdf' },
];

const renderFileCategoryList = (categories: FileCategory[]) => {
  return (
    <List>
      {categories.map((category, index) => (
        <ListItem sx={{ color: 'white !important', textDecoration: 'underline' }} key={index} component="a" href={category.fileUrl} download>
          <ListItemText
            primary={category.germanTitle}
          />
        </ListItem>
      ))}
    </List>
  );
};

const DownloadArea2: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Paper elevation={5}>
     <Box
        display="flex"
        flexDirection="column"
        bgcolor={colors.primary[400]}
        borderRadius="5px"
        marginTop="20px"
      >
    <Box
      ml="20px"
      mt="65px"
      sx={{
        display: "flex",
        minHeight: "93vh",
        justifyContent: 'space-around',
        color: 'white !important'
      }}     
    >
      <div>
        <Typography variant="h5" gutterBottom>
          1. Industriewasser
        </Typography>

        <ListItem sx={{ color: 'white !important', textDecoration: 'underline' }} component="a" href={'/downloads/dummy.pdf'} download>
          <ListItemText
            primary={'A. Ausschreibungstexte (lender_texts)'}
          />
        </ListItem>

        {renderFileCategoryList(industriwasserCategories)}


        <ListItem sx={{ color: 'white !important', textDecoration: 'underline' }} component="a" href={'/downloads/dummy.pdf'} download>
          <ListItemText
            primary={'B. Broschüren (brochure)'}
          />
        </ListItem>
        <ListItem sx={{ color: 'white !important', textDecoration: 'underline' }} component="a" href={'/downloads/dummy.pdf'} download>
          <ListItemText
            primary={'C. Sonstiges (miscellaneous)'}
          />
        </ListItem>
      </div>
      {/* Industriewasser Section */}

      <div>
        {/* Coracon Section */}
        <Typography variant="h5" gutterBottom>
          2. Coracon
        </Typography>
        <ListItem sx={{ color: 'white !important', textDecoration: 'underline' }} component="a" href={'/downloads/dummy.pdf'} download>
          <ListItemText
            primary={'A. Ausschreibungstexte (lender_texts)'}
          />
        </ListItem>


        {renderFileCategoryList(coraconCategories)}

        <ListItem sx={{ color: 'white !important', textDecoration: 'underline' }} component="a" href={'/downloads/dummy.pdf'} download>
          <ListItemText
            primary={'B. Broschüren (brochure)'}
          />
        </ListItem>

        <ListItem sx={{ color: 'white !important', textDecoration: 'underline' }} component="a" href={'/downloads/dummy.pdf'} download>
          <ListItemText
            primary={'C. Sonstiges (miscellaneous)'}
          />
        </ListItem>
      </div>

      </Box>
    </Box>
    </Paper>

  );
};

export default DownloadArea2;
