import accountSchema from "../../../models/accountSchema";
import LoginFormFields from "../fields/login";
import React from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useLogin } from "../../../api/auth";
import { showErrorAlert } from "../alerts";

const LoginForm = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate: NavigateFunction = useNavigate();
  const loginMutation = useLogin();

  const initialValues = {
    email: "",
    password: "",
  };

  const handleFormSubmit = async (values: any) => {
    try {
      const { email, password } = values;
      await loginMutation.mutateAsync({ email, password });
    } catch (err: any) {
      const { data } = err.response;
      showErrorAlert(data.message);
    }
  };
  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={accountSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <LoginFormFields
            isNonMobile={isNonMobile}
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            mr="15px"
            mt="20px"
          >
            <Button
              size="medium"
              color="secondary"
              variant="text"
              sx={{
                fontSize: "1rem",
                textTransform: "none",
              }}
              onClick={() => navigate("/forgot-password")}
            >
              Passwort vergessen?
            </Button>
            <Button
              size="medium"
              type="submit"
              color="secondary"
              variant="contained"
              sx={{
                fontSize: "1rem",
                textTransform: "none",
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? `Log-in....` : `Log-in`}
            </Button>
          </Box>
          <Box mt="20px">
            <Button
              size="medium"
              color="secondary"
              variant="text"
              sx={{
                fontSize: "1rem",
                textTransform: "none",
              }}
              onClick={() => navigate("/imprint")}
            >
              Impressum
            </Button>
        </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
