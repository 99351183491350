import React from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import Box from "@mui/joy/Box";
import useScript from "../../utils/useScript";
import extendTheme from "../../assets/themes/custom-theme";
import MyMessages from "./MyMessages";

const useEnhancedEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;

export default function JoyMessagesTemplate() {
  const status = useScript(`https://unpkg.com/feather-icons`);

  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof feather !== "undefined") {
      // @ts-ignore
      feather.replace();
    }
  }, [status]);
  return (
    <CssVarsProvider disableTransitionOnChange theme={extendTheme}>
      <Box sx={{ display: "flex", minHeight: "100dvh" }}>
        <Box flex={1}>
          <MyMessages />
        </Box>
      </Box>
    </CssVarsProvider>
  );
}
