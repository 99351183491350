import { plantDetailsBreadcrumb } from "../plants/plants-breadcrumbs";

export interface IParams {
  client: { name?: string; id: number };
  building: { name?: string; id: number };
  plant?: { name?: string; id: number };
  component?: { name?: string; id: number };
}

export const componentsListBreadcrumbs = (props: IParams) => {
  const { client, building, plant } = props;

  const plantsDetails = plantDetailsBreadcrumb({
    client: { name: client?.name, id: client?.id },
    building: { name: building?.name, id: building?.id },
    plant: { name: plant?.name, id: Number(plant?.id) },
  });

  const componentsList = [
    ...plantsDetails,
    {
      title: "Komponenten",
      path: `/clients/${client.id}/buildings/${building?.id}/plants/${plant?.id}/components`,
      disabledPath: true,
    },
  ];

  return componentsList.filter((item) => item.title !== "");
};

export const componentDetailsBreadcrumbs = (props: IParams) => {
  const { client, building, plant, component } = props;

  const plantsDetails = plantDetailsBreadcrumb({
    client: { name: client?.name, id: client?.id },
    building: { name: building?.name, id: building?.id },
    plant: { name: plant?.name, id: Number(plant?.id) },
  });

  const componentDetails = [
    ...plantsDetails,
    {
      title: "Komponenten",
      path: `/clients/${client.id}/buildings/${building?.id}/plants/${plant?.id}/components`,
    },
    {
      title: component?.name,
      path: `/clients/${client.id}/buildings/${building?.id}/plants/${plant?.id}/components`,
      disabledPath: true,
    },
  ];

  return componentDetails.filter((item) => item.title !== "");
};

export const createComponentsBreadcrumbs = (props: IParams) => {
  const { client, building, plant } = props;

  const plantsDetails = plantDetailsBreadcrumb({
    client: { name: client?.name, id: client?.id },
    building: { name: building?.name, id: building?.id },
    plant: { name: plant?.name, id: Number(plant?.id) },
  });

  const componentsList = [
    ...plantsDetails,
    {
      title: "Komponenten",
      path: `/clients/${client.id}/buildings/${building?.id}/plants/${plant?.id}/components`,
    },
    {
      title: "Erstellen",
      path: `/clients/${client.id}/buildings/${building?.id}/plants/${plant?.id}/components`,
      disabledPath: true,
    },
  ];

  return componentsList.filter((item) => item.title !== "");
};

export const updateComponentsBreadcrumbs = (props: IParams) => {
  const { client, building, plant, component } = props;

  const plantsDetails = plantDetailsBreadcrumb({
    client: { name: client?.name, id: client?.id },
    building: { name: building?.name, id: building?.id },
    plant: { name: plant?.name, id: Number(plant?.id) },
  });

  const componentsList = [
    ...plantsDetails,
    {
      title: "Komponenten",
      path: `/clients/${client.id}/buildings/${building?.id}/plants/${plant?.id}/components`,
    },
    {
      title: component?.name,
      path: `/clients/${client.id}/buildings/${building?.id}/plants/${plant?.id}/components/${component?.id}`,
      disabledPath: true,
    },
    {
      title: "Aktualisieren",
      path: `/clients/${client.id}/buildings/${building?.id}/plants/${plant?.id}/components/${component?.id}`,
      disabledPath: true,
    },
  ];

  return componentsList.filter((item) => item.title !== "");
};
