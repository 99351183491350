import React from "react";
import SetPasswordFields from "../fields/set-password";
import setPasswordValidation from "../../../validation/setPassword";
import { Box, Button, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useSetPassword } from "../../../api/auth";
import { showErrorAlert, showSuccessAlert } from "../alerts";

const SetPasswordForm = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const setPasswordMutation = useSetPassword();
  const navigate: NavigateFunction = useNavigate();
  const initialValues = {
    email: "",
    password: "",
    password_confirmation: "",
  };

  const handleFormSubmit = async (values: any) => {
    try {
      const { email, password, password_confirmation } = values;

      await setPasswordMutation.mutateAsync({
        email,
        password,
        password_confirmation,
      });

      await showSuccessAlert(`Das Passwort wurde erfolgreich festgelegt!`);

      navigate(`/login`);
    } catch (error: any) {
      const { data } = error.response;
      showErrorAlert(data.message);
    }
  };
  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={setPasswordValidation}
      validateOnBlur={false}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <SetPasswordFields
            isNonMobile={isNonMobile}
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <Box display="flex" justifyContent="end" m="15px">
            <Button
              size="medium"
              type="submit"
              color="secondary"
              variant="contained"
              sx={{
                fontSize: "1rem",
                textTransform: "none",
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? `Bestätigen....` : `Bestätigen`}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default SetPasswordForm;
