import Badge from "@mui/joy/Badge";
import IconButton from "@mui/joy/IconButton";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useFetchUnreadNotificationsCount } from "../../api/notification";

const NotificationIcon = () => {
  const navigate: NavigateFunction = useNavigate();
  const { data: unreadNotificationsCount, isError } =
    useFetchUnreadNotificationsCount();
  const counts = unreadNotificationsCount?.data; // Extract data from the 'data' property

  if (isError) {
    return (
      <IconButton disabled color="neutral" size="sm" variant="outlined">
        <NotificationsNoneIcon color="error" />
      </IconButton>
    );
  }

  return (
    <Badge badgeContent={counts} size="sm" color="danger">
      <IconButton
        color="neutral"
        size="sm"
        variant="outlined"
        onClick={() => {
          navigate("/notifications");
        }}
      >
        <NotificationsNoneIcon />
      </IconButton>
    </Badge>
  );
};

export default NotificationIcon;
