import React, { useEffect, useCallback } from "react";
import Swal from "sweetalert2";
import { AxiosError } from "axios";
import {
  useParams,
  useLocation,
  useNavigate,
  NavigateFunction,
} from "react-router-dom";
import { Box } from "@mui/material";
import Header from "../shared/header";
import { useEmailVerification } from "../../api/auth";

const EmailVerificationPage = () => {
  const { id, hash } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const expires = searchParams.get("expires");
  const signature = searchParams.get("signature");

  const navigate: NavigateFunction = useNavigate();
  const createEmailVerification = useEmailVerification();

  const verifyEmail = useCallback(
    async (id: string, hash: string, expires: string, signature: string) => {
      try {
        await createEmailVerification.mutateAsync({
          id,
          hash,
          expires,
          signature,
        });
        Swal.fire({
          allowOutsideClick: false,
          icon: "success",
          title: "Ihre E-Mail wurde erfolgreich verifiziert!",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/set-password`);
          }
        });
      } catch (err: AxiosError | any) {
        const { data } = err.response;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.message,
        });
      }
    },
    [createEmailVerification, navigate]
  );

  // Usage of verifyEmail in the code...
  useEffect(() => {
    if (id && hash && expires && signature) {
      verifyEmail(id, hash, expires, signature);
    }
  }, [id, hash, expires, signature]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh" /* Adjust the height as needed */
    >
      <Box width="60%" m="20px">
        <Box textAlign="center">
          <Header title="Aqua-Digital" subtitle="Email Verification" />
        </Box>
      </Box>
    </Box>
  );
};

export default EmailVerificationPage;
