export const firebaseConfig = {
  apiKey: "AIzaSyBEujSvjYdqik2NHR-o8-f85Un_1hoX4uY",
  authDomain: "aqua-concept-2c919.firebaseapp.com",
  projectId: "aqua-concept-2c919",
  storageBucket: "aqua-concept-2c919.appspot.com",
  messagingSenderId: "330397321651",
  appId: "1:330397321651:web:03aa891139e341d01923c6",
  measurementId: "G-DFFZYZ6XB5",
};

export const vapidKey =
  "BDIAzs5Sy7yv8oZuev_qFMeOVFrtO8jQ0D2ZQ9d1EBQlghZZ_9Jkz2x82DfIrmfsp3uribGaBik80kJnyfcRe1s";
