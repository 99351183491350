import { clientDetailsBreadcrumb } from "../clients/client-breadcrumb";

export interface IParams {
  client: { name?: string; id: number };
  building?: { name?: string; id: number };
}

export const buildingDetailsBreadcrumb = (props: IParams) => {
  const { client, building } = props;

  const clientDetails = clientDetailsBreadcrumb({
    client: { name: client?.name, id: client?.id },
  });

  const buildingDetails = [
    ...clientDetails,
    { title: "Gebäude", path: `/clients/${client.id}` },
    {
      title: building?.name ? building?.name : "Loading...",
      path: `/clients/${client.id}/buildings/${building?.id}`,
      disabledPath: true,
    },
  ];

  return buildingDetails.filter((item) => item.title !== "");
};

// Show breadcrumb in the create mode
export const creatbuildingBreadcrumb = (props: IParams) => {
  const { client } = props;

  return [
    { title: "Home", path: "/" },
    { title: "Kunden", path: "/clients" },
    {
      title: client?.name ? client?.name : "Loading...",
      path: `/clients/${client.id}`,
    },
    {
      title: "Gebäude",
      path: `/clients/${client.id}`,
      disabledPath: true,
    },
  ].filter((item) => item.title);
};
