import { ApiService } from "./api.service";
import { IResponse } from "../models/response";
import { MutationFunction, useMutation, useQuery } from "@tanstack/react-query";
import {
  PlantModel,
  PlantTypeModel,
  PlantTypeOptions,
  CreatePlantModel,
  PlantViewModel,
} from "../models/plants/plants.model";
import {
  TrafficLightModel,
  TrafficLightParametersModel,
} from "../models/traffic-light/traffic-light.model";
import { AxiosError } from "axios";
import { ErrorTypeModel } from "../models/error/error.model";

// Get list of plants
export const useFetchPlants = (
  buildingId?: string,
  page = 1,
  per_page: number | null = null,
  filters?: Record<string, unknown>,
  sort?: Record<string, unknown>
) => {
  const queryKey = [
    "buildings",
    buildingId,
    "plants",
    page,
    per_page,
    filters,
    sort,
  ];

  const { refetch, ...queryResult } = useQuery<
    IResponse<PlantModel[]>,
    AxiosError
  >(queryKey, async () => {
    const params: Record<string, unknown> = {
      page: page,
      per_page: per_page,
      sortField: sort?.field,
      sortDirection: sort?.sort,
      ...filters,
    };

    const plantsData = await ApiService.get(`/buildings/${buildingId}/plants`, {
      params,
    });
    return plantsData?.data;
  });
  return { ...queryResult, refetch };
};

// Get plants without pagination
export const useFetchPlantsWithoutPagination = (buildingId?: string) => {
  const queryKey = ["plants"];
  const { refetch, ...queryResult } = useQuery<
    IResponse<PlantModel[]>,
    AxiosError
  >(queryKey, () =>
    ApiService.get(`/buildings/${buildingId}/plants?pagination=0`)
  );
  return { ...queryResult, refetch };
};

// Get Plant Details
export const useGetPlantDetails = (id?: string) => {
  const queryKey = ["plant", id];

  const { refetch, ...queryResult } = useQuery<
    IResponse<PlantViewModel>,
    AxiosError
  >(queryKey, async () => {
    const plantData = await ApiService.get(`/plants/${id}`);
    return plantData;
  });
  return { ...queryResult, refetch };
};

// Get list of plant types
export const useFetchPlantTypes = () => {
  const queryKey = ["plant-types"];
  const { refetch, ...queryResult } = useQuery<
    IResponse<PlantTypeModel[]>,
    AxiosError
  >(queryKey, async () => {
    const plantsData = await ApiService.get(`/plant-types`);
    return plantsData;
  });
  return { ...queryResult, refetch };
};

// Get list of plant options
export const useFetchPlantOptions = () => {
  const queryKey = ["plant-options"];
  const { refetch, ...queryResult } = useQuery<
    IResponse<PlantTypeOptions>,
    AxiosError
  >(queryKey, async () => {
    const plantsData = await ApiService.get(`/plant-options`);
    return plantsData;
  });
  return { ...queryResult, refetch };
};

// Create new Plant
export const useCreatePlant = (buildingId?: string) => {
  const createPlant = useMutation<
    IResponse<CreatePlantModel>,
    AxiosError<ErrorTypeModel>,
    FormData
  >(async (formData: FormData) => {
    // Make the API request using the formData
    const response = await ApiService.postFormData(
      `/buildings/${buildingId}/plants`,
      formData
    );
    return response.data;
  });
  return createPlant;
};

// Delete Plant
export const deletePlant = async (plantId?: string) => {
  try {
    const response = await ApiService.delete(`/plants/${plantId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

// update Plant details
export const useUpdatePlant = (plantId?: string) => {
  const updatePlant = useMutation<
    IResponse<CreatePlantModel>,
    AxiosError<ErrorTypeModel>,
    FormData
  >(async (formData: FormData) => {
    const response = await ApiService.postFormData(
      `/plants/${plantId}?_method=PATCH`,
      formData
    );
    return response;
  });
  return updatePlant;
};

// Get traffic light
export const useFetchTrafficLight = (plantId?: string) => {
  const queryKey = ["plants", plantId, "traffic-lights"];
  const { refetch, ...queryResult } = useQuery<
    TrafficLightParametersModel[],
    AxiosError
  >(queryKey, async () => {
    const trafficLightData = await ApiService.get(
      `/plants/${plantId}/traffic-lights`
    );
    return trafficLightData?.data;
  });
  return { ...queryResult, refetch };
};

// Create Traffc Light
export const useCreateTrafficLight = (plantId?: string) => {
  const createTrafficLight: MutationFunction<any, any> = async (postData) => {
    const response = await ApiService.post(
      `/plants/${plantId}/traffic-lights`,
      postData
    );
    return response.data;
  };
  return useMutation(createTrafficLight);
};

//Get Traffic Light
export const useGetTrafficLightDetails = (id?: string) => {
  const queryKey = ["traffic-light", id];
  const { refetch, ...queryResult } = useQuery<
    IResponse<TrafficLightModel[]>,
    AxiosError
  >(queryKey, async () => {
    const trafficLightData = await ApiService.get(
      `/plants/${id}/traffic-lights`
    );
    return trafficLightData;
  });
  return { ...queryResult, refetch };
};

// Update Traffic Light
export const useUpdateTrafficLight = (id?: string) => {
  const updateTrafficLight: MutationFunction<any, any> = async (postData) => {
    const response = await ApiService.patch(
      `/plants/${id}/traffic-lights`,
      postData
    );
    return response.data;
  };
  return useMutation(updateTrafficLight);
};
