import axios, { AxiosResponse } from "axios";
import { BASE_URL } from "./index";
import { useMutation } from "@tanstack/react-query";
import { useSignIn } from "react-auth-kit";

export const useLogin = () => {
  const signIn = useSignIn();

  return useMutation<AxiosResponse, Error, { email: any; password: any }>(
    async ({ email, password }) => {
      const url = `${BASE_URL}/auth/login`;
      const response: AxiosResponse = await axios.post(url, {
        email,
        password,
      });
      return response;
    },
    {
      onSuccess: (response) => {
        const expiresInDays = 3650;
        const expiresInSeconds = expiresInDays * 24 * 60 * 60;
        const { data } = response.data;
        signIn({
          token: response.data.token,
          expiresIn: expiresInSeconds,
          tokenType: "Bearer",
          authState: {
            token: data.access_token,
            id: data.user.id,
            fullName: data.user.first_name + " " + data.user.last_name,
            status: data.user.status,
            email: data.user.email,
            access_token: data.access_token,
            role: data.user.associate_account,
          },
        });
        setTimeout(() => {
          window.location.replace("/");
        }, 500);
      },
    }
  );
};

export const useForgotPassword = () => {
  return useMutation<AxiosResponse, Error, { email: any }>(
    async ({ email }) => {
      const url = `${BASE_URL}/auth/send-reset-password-link`;
      const response: AxiosResponse = await axios.post(url, {
        email,
      });
      return response;
    }
  );
};

export const useResetPassword = () => {
  return useMutation<
    AxiosResponse,
    Error,
    { token: string; password: string; password_confirmation: string }
  >(async ({ token, password, password_confirmation }) => {
    const url = `${BASE_URL}/auth/reset-password`;
    const response: AxiosResponse = await axios.post(url, {
      token,
      password,
      password_confirmation,
    });
    return response;
  });
};

export const useEmailVerification = () => {
  return useMutation<
    AxiosResponse,
    Error,
    { id: string; hash: string; expires: string; signature: string }
  >(async ({ id, hash, expires, signature }) => {
    const url = `${BASE_URL}/auth/email/verify/${id}/${hash}?expires=${expires}&signature=${signature}`;
    const response: AxiosResponse = await axios.get(url, {
      headers: {
        Accept: "application/json",
      },
    });
    return response;
  });
};

export const useSetPassword = () => {
  return useMutation<
    AxiosResponse,
    Error,
    { email: string; password: string; password_confirmation: string }
  >(async ({ email, password, password_confirmation }) => {
    const url = `${BASE_URL}/auth/set-password`;
    const response: AxiosResponse = await axios.post(url, {
      email,
      password,
      password_confirmation,
    });
    return response;
  });
};
