import React from "react";
import { Container, Paper } from "@mui/material";
import { Box } from "@mui/joy";
import Header from "../shared/header";

const ImprintPage = () => {
	return (
		<Container>
			<Box my={5}>
				<Box display="flex" justifyContent="center" alignItems="center" mb={2}>
					<Header title="Impressum" subtitle="" />
				</Box>
				<Paper elevation={5}>
					<Box p={5} fontSize={17}>
						Kontaktaufnahme:
						<br />
						AQUA-CONCEPT Gesellschaft für Wasserbehandlung mbH
						<br />
						Am Kirchenhölzl 13
						<br />
						82166 Gräfelfing
						<br />
						Telefon: 089.899 369-0
						<br />
						info@aqua-concept-gmbh.eu
						{/* <a href="mailto:info@aqua-concept-gmbh.eu">info@aqua-concept-gmbh.eu</a> */}
						<br />
						<br />
						Geschäftsführer:
						<br />
						Dr. Andreas Detig und Dr. Frank Höhne
						<br />
						<br />
						Verantwortlich im Sinne des § 55 Abs. 2 RStV:
						<br />
						Dr. Andreas Detig
						<br />
						<br />
						Registergericht:
						<br />
						Amtsgericht München
						<br />
						<br />
						Registernummer:
						<br />
						HRB 96757
						<br />
						<br />
						Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz:
						<br />
						DE 128215086
						<br />
						<br />
						Urheberrecht:
						<br />
						Inhalt und Struktur der Website der aqua-concept GmbH sind urheberrechtlich geschützt. Die
						Vervielfältigung von Informationen oder Daten, insbesondere die Verwendung von Texten, Textteilen oder
						Bildmaterial, bedarf der vorherigen schriftlichen Zustimmung der aqua-concept GmbH.
					</Box>
				</Paper>
			</Box>
		</Container>
	);
};

export default ImprintPage;
