export type IdentityProps = {
  id: string;
  name: string;
  username: string;
  avatar: string;
  online: boolean;
};

export type MessageProps = {
  id: string;
  content: string;
  timestamp: string;
  read?: boolean;
  sender: IdentityProps;
  attachment:
    | {
        id: string;
        fileName: string;
        type: string;
        size: string;
        url: string;
      }
    | undefined;
};

export type ChatProps = {
  id: string;
  sender: IdentityProps;
  unread: number;
  lastseen: string;
  lastMessage: string;
  timestamp: string;
  type: "group" | "dialog";
  members?: IdentityProps[];
};

export enum MessageTypes {
  WentOnline = 1,
  WentOffline = 2,
  TextMessage = 3,
  FileMessage = 4,
  IsTyping = 5,
  MessageRead = 6,
  ErrorOccurred = 7,
  MessageIdCreated = 8,
  NewUnreadCount = 9,
  TypingStopped = 10,
  GroupTextMessage = 11,
  GroupFileMessage = 12,
  GroupMessageIdCreated = 13,
  SelectGroup = 14,
  NewGroupOpen = 15,
}
