import React, { useState } from "react";
import { userId } from "../../api";
import Header from "../shared/header";
import DataTable from "../shared/table";
import { Box, useMediaQuery } from "@mui/material";
import {
  setAllNotificationsAsRead,
  useFetchNotifications,
} from "../../api/notification";
import { useQueryClient } from "@tanstack/react-query";
import { columns } from "./notification.columns";
import CustomButton from "../shared/custom-button";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {
  GridFilterItem,
  GridPaginationModel,
  GridSortItem,
} from "@mui/x-data-grid";
import { transformFilterItemToFilterObject } from "../../utils/helper";

const NotificationsPage = () => {
  const isMobile = useMediaQuery("(min-width: 600px)");
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  // Filter
  const [filters, setFilters] = useState({});
  // Sort
  const [sort, setSort] = useState({});
  const queryClient = useQueryClient();
  const queryKey = ["unread-notifications-count"];

  // Fetch Notifications Data
  const {
    data: notificationsData,
    isLoading,
    isError,
    refetch: refetchNotifications,
  } = useFetchNotifications(
    userId,
    currentPage,
    itemsPerPage,
    undefined,
    filters,
    sort
  );

  const notifications = notificationsData?.data;
  const totalItems = notificationsData?.pager?.total;

  const handlePageChange = (newPage: GridPaginationModel) => {
    // Fetch new data from the server based on the new page
    setCurrentPage(newPage.page + 1);
    setItemsPerPage(newPage.pageSize);
  };

  const handleFilterModelChange = (filterItem: GridFilterItem) => {
    const filterObject = transformFilterItemToFilterObject(filterItem);
    setFilters(filterObject);
  };

  const handleSortModelChange = (sortItem: GridSortItem) => {
    setSort(sortItem);
  };

  const handleRefetch = () => {
    refetchNotifications();
    queryClient.invalidateQueries(queryKey);
  };

  const markAllAsRead = async () => {
    await setAllNotificationsAsRead();
    handleRefetch();
  };

  return (
    <>
      {!isError && (
        <Box
          sx={{
            marginTop: isMobile ? "40px" : "60px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header title="Benachrichtigungen" />
            <CustomButton
              handleOnClick={markAllAsRead}
              startIcon={<DoneAllIcon />}
              label="als gelesen markieren"
            />
          </Box>
          <Box marginTop="40px">
            <DataTable
              parentPath="notifications"
              data={notifications ?? []}
              fieldsNames={columns}
              boxHeight={75.5}
              notifications={true}
              currentPage={currentPage - 1}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
              refetch={handleRefetch}
              ssrFilter={true}
              isLoading={isLoading}
              onFilterChange={handleFilterModelChange}
              onSortChange={handleSortModelChange}
              ssrSort={true}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default NotificationsPage;
