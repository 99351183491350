import React from "react";
import { Box } from "@mui/material";
import Header from "../shared/header";
import SetPasswordForm from "../shared/forms/set-password";

const SetPasswordPage = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh" /* Adjust the height as needed */
    >
      <Box width="60%" m="20px">
        <Box textAlign="center">
          <Header
            title="careblue controllcenter"
            subtitle="Bitte bestätigen Sie Ihr Konto"
          />
        </Box>
        <SetPasswordForm />
      </Box>
    </Box>
  );
};

export default SetPasswordPage;
