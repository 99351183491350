import * as React from "react";
import Box from "@mui/joy/Box";
import ListDivider from "@mui/joy/ListDivider";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { ListItemButtonProps } from "@mui/joy/ListItemButton";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import CircleIcon from "@mui/icons-material/Circle";
import AvatarWithStatus from "./AvatarWithStatus";
import { IdentityProps, MessageTypes } from "../../utils/chat-types";
import { toggleMessagesPane } from "../../utils/chat-helper";
import GroupedAvatars from "./GroupedAvatars";
import useSocketStore from "../../stores/store-socket";

type ChatListItemProps = ListItemButtonProps & {
  id: string;
  sender: IdentityProps;
  unread: number;
  lastseen: string;
  lastMessage: string;
  timestamp: string;
  type: "group" | "dialog";
  members?: IdentityProps[];
  self: IdentityProps | null;
};

export default function ChatListItem({
  id,
  sender,
  unread,
  lastseen,
  lastMessage,
  timestamp,
  type,
  members,
  self,
}: ChatListItemProps) {
  const { selectedChat, setSelectedChat, sendNewMessage } = useSocketStore();
  const selected =
    selectedChat && selectedChat.id === id && type == selectedChat.type;
  return (
    <React.Fragment>
      <ListItem>
        <ListItemButton
          onClick={() => {
            toggleMessagesPane();
            setSelectedChat({
              id,
              sender,
              unread,
              lastseen,
              lastMessage,
              timestamp,
              type,
              members,
            });
            if (type == "group") {
              const event = {
                group_id: sender.id,
                user_pk: self?.id,
                msg_type: MessageTypes.SelectGroup,
              };
              sendNewMessage(event);
            }
          }}
          selected={selected}
          variant={selected ? "soft" : "plain"}
          color="neutral"
          sx={{
            flexDirection: "column",
            alignItems: "initial",
            gap: 1,
            fontWeight: "normal",
          }}
        >
          <Stack direction="row" spacing={1.5}>
            {type == "dialog" && (
              <AvatarWithStatus online={sender.online} src={sender.avatar} />
            )}
            {type == "group" && <GroupedAvatars members={members ?? []} />}
            <Box sx={{ flex: 1 }}>
              <Typography fontSize="sm" fontWeight="lg">
                {sender.name}
              </Typography>
            </Box>
            {lastMessage && (
              <Box sx={{ lineHeight: 1, textAlign: "right" }}>
                <Typography
                  level="body2"
                  display={{ xs: "none", md: "block" }}
                  noWrap
                >
                  {timestamp}
                </Typography>
                {unread && unread >= 1 ? (
                  <CircleIcon sx={{ fontSize: 10 }} color="primary" />
                ) : null}
              </Box>
            )}
          </Stack>
          <Typography
            level="body2"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {lastMessage ?? "Neuer Gespräch!"}
          </Typography>
        </ListItemButton>
      </ListItem>
      <ListDivider sx={{ margin: 0 }} />
    </React.Fragment>
  );
}
