import { GridColDef, GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import { useMemo, useState } from 'react';
import { useFetchCurrentUserTasks, useFetchDisturbancesClient, useFetchResovledTasks, useFetchUpComingUserTasks } from "../api/components";
import { Subjects } from "../casl/ability";
import { formatDateToShortDate, transformFilterItemToFilterObject } from "../utils/helper";

interface Task {
  id: number;
  task_id: number;
  task_name: string;
  status: string;
  repeated_type: string;
  start_date: string;
  due_date: string;
  created_by: string;
}

interface IPager {
  page: number;
  pageSize: number;
}


interface FormattedTask {
  task_name: string;
  status: string;
  assignedUser: string
  due_date: string | null;
  [key: string]: any;
}

interface TableData {
  rows: any;
  columns: any;
  paginationModel: any,
  sortModel: any,
  filterModel: any,
  isLoading: any,
  title: string,
  permission: Subjects,
  parentPath?: string,
  customLink?: string
}


export const useDashboardTables = (): TableData[] => {

  const columns: GridColDef[] = [
    {
      field: "task_name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "assignedUser",
      headerName: "Zugewiesen an",
      flex: 1,
    },
    {
      field: "due_date",
      headerName: "Fälligkeitsdatum",
      flex: 1,
    },
  ];

  const clientDisturbancesColumns: GridColDef[] = [
    {
      field: "plant.name",
      headerName: "Wo",
      flex: 1,
      valueGetter: (params) => params.row.plant.name,
    },
    {
      field: "description",
      headerName: "Beschreibung",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },

    {
      field: "when",
      headerName: "Wann",
      flex: 1,
    },

    {
      field: "due_date",
      headerName: "Fälligkeitsdatum",
      flex: 1,
    },

    {
      field: "solution",
      headerName: "Lösung",
      flex: 1,
    },

  ]

  const clientResolvedColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },

    {
      field: "building",
      headerName: "Gebäude",
      flex: 1,
      valueGetter: (params) => params.row.building.name,
    },
    {
      field: "assigned_user",
      headerName: "Zugewiesen an",
      flex: 1,
      valueGetter: (params) => `${params.row.assigned_user?.first_name} ${params.row.assigned_user?.last_name}`,
    },
    
    
  ]

 
 

  const [selfCurrentPagination, setSelfCurrentPagination] = useState<IPager>({
    page: 1,
    pageSize: 10,
  });

  const [otherCurrentPagination, setOtherCurrentPagination] = useState<IPager>({
    page: 1,
    pageSize: 10,
  });

  const [selfUpComingPagination, setSelfUpComingPagination] = useState<IPager>({
    page: 1,
    pageSize: 10,
  });

  const [otherUpComingPagination, setOtherUpComingPagination] = useState<IPager>({
    page: 1,
    pageSize: 10,
  });

  const [clientDisturbancesPagination, setClientDisturbancesPagination] = useState<IPager>({
    page: 1,
    pageSize: 10,
  });

  
  const [clientResolvedTasksPagination, setClientResolvedTasksPagination] = useState<IPager>({
    page: 1,
    pageSize: 10,
  });


  const [selfCurrentFilter, setSelfCurrentFilter] = useState<GridFilterModel>();

  const [otherCurrentFilter, setOtherCurrentFilter] = useState<GridFilterModel>();

  const [selfCurrentSort, setSelfCurrentSort] = useState<GridSortModel>();

  const [otherCurrentSort, setOtherCurrentSort] = useState<GridSortModel>();

  const [selfUpComingFilter, setSelfUpComingFilter] = useState<GridFilterModel>();

  const [otherUpComingFilter, setOtherUpComingFilter] = useState<GridFilterModel>();

  const [selfUpComingSort, setSelfUpComingSort] = useState<GridSortModel>();

  const [otherUpComingSort, setOtherUpComingSort] = useState<GridSortModel>();

  const [clientDisturbancesFilter, setClientDisturbancesFilter] = useState<GridFilterModel>();

  const [clientDisturbancesSort, setClientDisturbancesSort] = useState<GridSortModel>();



  const { data: selfCurrentTasksData, isLoading: selfCurrentTasksLoading} = useFetchCurrentUserTasks({ user_type: 'self', itemsPerPage: selfCurrentPagination.pageSize, page: selfCurrentPagination.page, ...transformFilterItemToFilterObject(selfCurrentFilter?.items?.[0] || { field: '', value: '', operator: '' }) });
  const { data: otherCurrentTasksData, isLoading: otherCurrentTasksLoading} = useFetchCurrentUserTasks({ user_type: 'other', itemsPerPage: otherCurrentPagination.pageSize, page: otherCurrentPagination.page, ...transformFilterItemToFilterObject(otherCurrentFilter?.items?.[0] || { field: '', value: '', operator: '' }) });


  const { data: selfUpComingTasksData, isLoading: selfUpComingTasksLoading} = useFetchUpComingUserTasks({ user_type: 'self', itemsPerPage: selfUpComingPagination.pageSize, page: selfUpComingPagination.page, ...transformFilterItemToFilterObject(selfUpComingFilter?.items?.[0] || { field: '', value: '', operator: '' }) });
  const { data: otherUpComingTasksData, isLoading: otherUpComingTasksLoading } = useFetchUpComingUserTasks({ user_type: 'other', itemsPerPage: otherUpComingPagination.pageSize, page: otherUpComingPagination.page, ...transformFilterItemToFilterObject(otherUpComingFilter?.items?.[0] || { field: '', value: '', operator: '' }) });

  const { data: clientDisturbancesData, isLoading: clientDisturbancesLoading} = useFetchDisturbancesClient({ itemsPerPage: clientDisturbancesPagination.pageSize, page: clientDisturbancesPagination.page, ...transformFilterItemToFilterObject(clientDisturbancesFilter?.items?.[0] || { field: '', value: '', operator: '' }) })
  const { data: clientResolvedTasksData, isLoading: clientResolvedTasksLoading } = useFetchResovledTasks({  itemsPerPage:  clientResolvedTasksPagination.page})


  const formatTasks = (tasks: Task[] | undefined): FormattedTask[] => {
    return tasks?.map(task => ({
      task_name: task.task_name,
      status: task.status,
      repeated_type: task.repeated_type,
      assignedUser: task.created_by,
      due_date: formatDateToShortDate(new Date(task.due_date)),
      id: task.id,
      task_id: task.task_id,
      start_date: task.start_date,
    })) || [];
  };

  const selfCurrentTasks = useMemo(() => formatTasks(selfCurrentTasksData?.data), [selfCurrentTasksData]);
  const otherCurrentTasks = useMemo(() => formatTasks(otherCurrentTasksData?.data), [otherCurrentTasksData]);

  const selfUpComingTasks = useMemo(() => formatTasks(selfUpComingTasksData?.data), [selfUpComingTasksData]);
  const otherUpComingTasks = useMemo(() => formatTasks(otherUpComingTasksData?.data), [otherUpComingTasksData]);

  const clientDisturbancesTasks = useMemo(() => clientDisturbancesData?.data, [clientDisturbancesData]);

  const clientResolvedTasks = useMemo(() => clientResolvedTasksData?.data, [clientResolvedTasksData]);


  return [
    {
      rows: selfCurrentTasks,
      columns,
      paginationModel: {
        data: {
          ...selfCurrentPagination,
          total: selfCurrentTasksData?.pager.total
        },
        setPagination: setSelfCurrentPagination,
      },
      sortModel: {
        sort: selfCurrentSort,
        setSort: setSelfCurrentSort
      },
      filterModel: {
        filter: selfCurrentFilter,
        setFilter: setSelfCurrentFilter
      },
      isLoading: selfCurrentTasksLoading,
      title: "Aufgaben von mir",
      permission: "Self Activities",
      parentPath: 'activity/sub-tasks'

    },
    {
      rows: otherCurrentTasks,
      columns,
      paginationModel: {
        data: {
          ...otherCurrentPagination,
          total: otherCurrentTasksData?.pager.total,
        },
        setPagination: setOtherCurrentPagination,
      },
      sortModel: {
        sort: otherCurrentSort,
        setSort: setOtherCurrentSort
      },
      filterModel: {
        filter: otherCurrentFilter,
        setFilter: setOtherCurrentFilter
      },
      isLoading: otherCurrentTasksLoading,
      title: "Aufgaben von anderen",
      permission: "Other Activities",
      parentPath: 'activity/sub-tasks'

    },
    {
      rows: selfUpComingTasks,
      columns,
      paginationModel: {
        data: {
          ...selfUpComingPagination,
          total: selfUpComingTasksData?.pager.total
        },
        setPagination: setSelfUpComingPagination,
      },
      sortModel: {
        sort: selfUpComingSort,
        setSort: setSelfUpComingSort
      },
      filterModel: {
        filter: selfUpComingFilter,
        setFilter: setSelfUpComingFilter
      },
      isLoading: selfUpComingTasksLoading,
      title: "Bevorstehende Aufgaben von mir",
      permission: "Self Activities",
      parentPath: 'activity/sub-tasks'

    },
    {
      rows: otherUpComingTasks,
      columns,
      paginationModel: {
        data: {
          ...otherUpComingPagination,
          total: otherUpComingTasksData?.pager.total
        },
        setPagination: setOtherUpComingPagination,
      },
      sortModel: {
        sort: otherUpComingSort,
        setSort: setOtherUpComingSort
      },
      filterModel: {
        filter: otherUpComingFilter,
        setFilter: setOtherUpComingFilter
      },
      isLoading: otherUpComingTasksLoading,
      title: "Bevorstehende Aufgaben von anderen",
      permission: "Other Activities",
      parentPath: 'activity/sub-tasks'

    },
    {
      rows: clientDisturbancesTasks,
      columns: clientDisturbancesColumns,
      paginationModel: {
        data: {
          ...clientDisturbancesPagination,
          total: clientDisturbancesData?.pager?.total
        },
        setPagination: setClientDisturbancesPagination,
      },
      sortModel: {
        sort: clientDisturbancesSort,
        setSort: setClientDisturbancesSort
      },
      filterModel: {
        filter: clientDisturbancesFilter,
        setFilter: setClientDisturbancesFilter
      },
      isLoading: clientDisturbancesLoading,
      title: "Störungen",
      permission: "Disturbances Client",
      customLink: '/clients/{client_id}/buildings/{building_id}/plants/{plant_id}/disturbances/{disturbances_id}'

    },
    {
      rows: clientResolvedTasks,
      columns: clientResolvedColumns,
      paginationModel: {
        data: {
          ...clientResolvedTasksPagination,
          total: clientResolvedTasksData?.pager?.total
        },
        setPagination: setClientResolvedTasksPagination,
      },
      sortModel: {
        sort: null,
        setSort: null
      },
      filterModel: {
        filter: null,
        setFilter: null
      },
      isLoading: clientResolvedTasksLoading,
      title: "Gelöste Aufgaben",
      permission: "Resolved Tasks Client",
      customLink: '/clients/{client_id}/buildings/{building_id}/history/tasks/{task_id}'
    },
  ];
};
