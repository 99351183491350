import * as React from "react";
import Avatar from "@mui/joy/Avatar";
import Chip from "@mui/joy/Chip";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import CircleIcon from "@mui/icons-material/Circle";
import { toggleMessagesPane } from "../../utils/chat-helper";
import useScript from "../../utils/useScript";
import EditGroupModal from "./EditGroupModal";
import GroupedAvatars from "./GroupedAvatars";
import useSocketStore from "../../stores/store-socket";

type MessagesPaneHeaderProps = {
  typing: boolean;
};

const useEnhancedEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;

export default function MessagesPaneHeader({
  typing,
}: MessagesPaneHeaderProps) {
  const { selectedChat } = useSocketStore();
  const status = useScript(`https://unpkg.com/feather-icons`);

  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof feather !== "undefined") {
      // @ts-ignore
      feather.replace();
    }
  }, [status]);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        borderBottom: "1px solid",
        borderColor: "divider",
      }}
      py={{ xs: 2, md: 2 }}
      px={{ xs: 1, md: 2 }}
    >
      <Stack direction="row" spacing={{ xs: 1, md: 2 }} alignItems="center">
        <IconButton
          variant="plain"
          color="neutral"
          size="sm"
          sx={{
            display: { xs: "inline-flex", sm: "none" },
          }}
          onClick={() => toggleMessagesPane()}
        >
          <i data-feather="chevron-left" />
        </IconButton>
        {selectedChat && selectedChat.type == "dialog" && (
          <Avatar size="lg" src={selectedChat.sender.avatar} />
        )}
        {selectedChat && selectedChat.type == "group" && (
          <GroupedAvatars members={selectedChat.members ?? []} />
        )}
        <div>
          <Typography
            fontWeight="lg"
            fontSize="lg"
            noWrap
            endDecorator={
              selectedChat &&
              selectedChat.sender.online &&
              selectedChat.type == "dialog" ? (
                <Chip
                  variant="outlined"
                  size="sm"
                  color="neutral"
                  sx={{
                    "--Chip-radius": "6px",
                  }}
                  startDecorator={
                    <CircleIcon sx={{ fontSize: 8 }} color="success" />
                  }
                  slotProps={{ root: { component: "span" } }}
                >
                  {typing ? "Typing..." : "Online"}
                </Chip>
              ) : undefined
            }
          >
            {selectedChat?.sender.name}
          </Typography>
        </div>
      </Stack>
      <Stack spacing={1} direction="row" alignItems="center">
        {selectedChat && selectedChat.type == "group" && (
          <EditGroupModal
            id={selectedChat.sender.id}
            members={selectedChat.members}
            name={selectedChat.sender.username}
          ></EditGroupModal>
        )}
      </Stack>
    </Stack>
  );
}
